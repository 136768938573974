import * as React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { Button, Stack, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'
import { useState } from 'react'
import { LoadingButton } from '@mui/lab'
import UploadFileIcon from '@mui/icons-material/UploadFile'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        xs: '70%',
        md: 800
    },
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

export default function PaymentLinksModal({
    open,
    current,
    handleSendEmails,
    handleClose,
    sending
}) {
    const { t } = useTranslation()

    const [list, setList] = useState()

    const handleOnChange = (event) => {
        setList(event.target.value)
    }

    const handleSubmit = () => {
        handleSendEmails(current, list)
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <TextField
                    label={t('Email list')}
                    placeholder='correo-1@dominio.com,correo-2@dominio.com,correo-3@dominio.com,...'
                    multiline
                    rows={4}
                    fullWidth
                    onChange={handleOnChange}
                />
                <Typography variant="subtitle1" sx={{
                    fontSize: '10px',
                    color: 'gray',
                    mt: 1.5
                }}>
                    {t('Enter all the email accounts with which you would like to share this payment link')}.
                </Typography>
                <Typography variant="subtitle1" sx={{
                    fontSize: '10px',
                    color: 'gray',
                }}>
                    {t('You can enter one or more addresses, separated by commas (,)')}
                </Typography>

                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={2}
                    mt={2}
                >
                    <LoadingButton
                        variant="contained"
                        loading={sending}
                        loadingPosition="start"
                        startIcon={<UploadFileIcon />}
                        size="small"
                        sx={{
                            width: {
                                xs: '100%',
                                md: 'auto'
                            }
                        }}
                        disabled={sending || !list}
                        onClick={handleSubmit}
                    >{t('Send')}</LoadingButton>

                    <Button
                        variant="text"
                        onClick={() => handleClose()}
                        sx={{
                            width: {
                                xs: '100%',
                                md: 'auto'
                            }
                        }}
                        startIcon={<CloseIcon />}>{t('Cancel')}</Button>
                </Stack>

            </Box>
        </Modal>
    )
}
