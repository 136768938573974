import { Card, CardContent, Skeleton } from '@mui/material'

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie as PieChart } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/system'
import ChartHeader from './ChartHeader'
import { useState, useEffect } from 'react'

ChartJS.register(ArcElement, Tooltip, Legend)

export default function Pie({
    data,
    days,
    loading
}) {
    const { t } = useTranslation()

    const [datasetData, setDatasetData] = useState([])
    const [labels, setLabels] = useState([])

    useEffect(() => {
        const total = data.reduce((a, b) => a + b.count, 0)
        setDatasetData(data.map(d => d.count))
        setLabels(data.map(d => (t(d.status_) + ' [' + Math.round((d.count / total) * 100) + '%]')))
    }, [loading, data, t])

    const pieData = {
        labels: labels,
        datasets: [
            {
                label: t('Sales conversion'),
                data: datasetData,
                backgroundColor: [
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(100, 140, 36, 0.2)',
                    'rgba(255, 99, 132, 0.2)'
                ],
                borderColor: [
                    'rgba(255, 159, 64, 1)',
                    'rgba(100, 140, 36, 1)',
                    'rgba(255, 99, 132, 1)'
                ],
                borderWidth: 1,
            },
        ],
    }

    return (
        <>
            <Card>
                <CardContent>
                    {!loading ?
                        <Box>
                            <ChartHeader
                                title={t('Sales conversion rate')}
                                subtitle={t('Last {{days}} days', { days: days })}
                            />

                            <PieChart data={pieData} width={'90%'}/>
                        </Box> :
                        <Box>
                            <Skeleton variant="text" animation="wave" height={50} />
                            <Skeleton variant="rectangular" width='100%' height={100} />
                        </Box>
                    }
                </CardContent>
            </Card>
        </>
    )
}
