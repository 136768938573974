import { useState } from 'react'
import { LoadingButton } from '@mui/lab'
import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    OutlinedInput
} from '@mui/material'
import Button from '@mui/material/Button'
import { useTranslation } from 'react-i18next'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import InputAdornment from '@mui/material/InputAdornment'
import AlertMessage from '../../Misc/AlertMessage'


export default function BalanceModal({
    handleConfirm,
    handleClose,
    amount,
    currency,
    setAmount,
    open = false,
    requesting = true
}) {
    const { t } = useTranslation()

    const [isChecked, setIsChecked] = useState(false)

    const handleOnChange = (event) => {
        setAmount(event.target.value)
    }

    const handleOnChecked = (event) => {
        setIsChecked(event.target.checked)
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {t('Cashouts balance')}
            </DialogTitle>
            <DialogContent>
                <FormControl sx={{ m: 1 }} fullWidth>
                    <InputLabel htmlFor="outlined-adornment-amount">{t('Amount')}</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-amount"
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        endAdornment={<InputAdornment position="end">{currency}</InputAdornment>}
                        label="Amount"
                        value={amount}
                        onChange={handleOnChange}
                        placeholder='1000'
                    />
                </FormControl>

                <FormGroup style={{ marginLeft: '.5rem' }}>
                    <FormControlLabel
                        control={<Checkbox checked={isChecked} onChange={handleOnChecked} />}
                        label={t('I confirm the entered amount is correct')}
                    />
                </FormGroup>

                {amount > 0 && (<AlertMessage
                    type='info'
                    message={t('Upon completion of this operation, {{amount}} {{currency}} will be transferred from your Payins account to your Cashouts account', {
                        amount: new Intl.NumberFormat('es-CL').format(amount),
                        currency: currency
                    })}
                />)}
            </DialogContent>
            <DialogActions sx={{
                padding: '1.5rem'
            }}>
                <Button onClick={handleClose} disabled={requesting}>{t('Cancel')}</Button>
                <LoadingButton
                    onClick={handleConfirm}
                    autoFocus
                    loading={requesting}
                    endIcon={<ChevronRightIcon />}
                    loadingPosition="end"
                    variant='contained'
                    disabled={requesting || amount <= 0 || !isChecked}
                >
                    {t('Confirm')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
