import React from 'react'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import CurrencyValue from '../../TableUI/CurrencyValue'


export default function WithdrawalsTableRow({
  title,
  available = 0,
  total = 0,
  total_sales = 0,
  currency,
  country = null,
  ...otherProps
}) {

  return (
    <TableRow {...otherProps}>
      <TableCell component="th" scope="row">
        {title}
      </TableCell>
      <TableCell align="right">
        <CurrencyValue amount={total_sales} currency={currency} />
      </TableCell>
      <TableCell align="right">
        <CurrencyValue amount={total} currency={currency} />
      </TableCell>
      <TableCell align="right">
        <CurrencyValue amount={available} currency={currency} />
      </TableCell>
    </TableRow>
  )
}
