import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableActions from "../../TableUI/TableActions";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import Pagination from "../../Pagination/Pagination";
import TableBackdrop from "../../TableUI/TableBackdrop";
import DescriptionIcon from "@mui/icons-material/Description";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router";
import { Chip, Link, Typography } from "@mui/material";
import DatetimeValue from "../../TableUI/DatetimeValue";
import { GetFileByLanguage } from "./VersionsTable";

export default function DocumentsTable({
  data,
  pages = 1,
  page = 1,
  loading = false,
  handleOnPageChange,
  viewDocument,
  language,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <Paper
        sx={{
          width: {
            xs: "calc(100vw - 80px)",
            sm: "calc(100vw - 330px)",
          },
          overflow: "hidden",
        }}
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{t("State")}</TableCell>
                <TableCell>{t("Document")}</TableCell>
                <TableCell>{t("Version")}</TableCell>
                <TableCell>{t("Deadline")}</TableCell>
                <TableCell>{t("Published")}</TableCell>
                <TableCell>{t("Signed")}</TableCell>
                <TableCell align="right">&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length ? (
                data.map((row, idx) => (
                  <TableRow
                    key={idx}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>
                      <Typography display={"inline-block"}>
                        {t(row.versions[0].status)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Link
                        underline="hover"
                        color="blue"
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          viewDocument(
                            GetFileByLanguage(row.versions[0].files, language),
                            idx
                          )
                        }
                      >
                        {row.name}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {row.versions[0] ? (
                        <Chip
                          label={row.versions[0].version}
                          color="success"
                          size="small"
                          variant="outlined"
                          sx={{
                            marginLeft: ".5rem",
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell>
                      {row.versions[0]?.deadline? (
                        <DatetimeValue
                          date={row.versions[0].deadline}
                          withOutTime={true}
                        />
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell>
                      {row.versions[0] ? (
                        <DatetimeValue
                          date={row.versions[0].files[0]?.published_at}
                          withOutTime={true}
                        />
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell>
                      {row.versions[0] ? (
                        <DatetimeValue
                          date={
                            GetFileByLanguage(row.versions[0].files, language)
                              ?.data_sign?.created_at
                          }
                        />
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell align="right">
                      <TableActions
                        actions={[
                          {
                            id: `view-${row.id}`,
                            handleClick: () =>
                              viewDocument(
                                GetFileByLanguage(
                                  row.versions[0].files,
                                  language
                                ),
                                idx
                              ),
                            icon: <VisibilityIcon />,
                            title: t("View"),
                          },
                          {
                            id: `version-${row.id}`,
                            handleClick: () =>
                              navigate(`/documents/versions/${row.id}`, {
                                id: row.id,
                                state: { document: row },
                              }),
                            icon: <DescriptionIcon />,
                            title: t("Previous versions"),
                          },
                        ]}
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  {loading ? (
                    <TableBackdrop open={loading} />
                  ) : (
                    <TableCell sx={{ textAlign: "center" }} colSpan={100}>
                      {t("No records found")}
                    </TableCell>
                  )}
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Pagination
        handleOnPageChange={handleOnPageChange}
        page={page}
        pages={pages}
      />
    </>
  );
}
