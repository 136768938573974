import React from "react";
import { Box, Button, colors, Modal, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useAuth from "../../../hooks/useAuth";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import { parseErrors } from "../../../services/utils";
import DatetimeValue from "../../TableUI/DatetimeValue";
import jwt_decode from "jwt-decode";

export default function ModalDocument({
  modalOpen,
  setModalOpen,
  setDataList,
  dataList,
  current,
  idx,
}) {
  const { t } = useTranslation();
  const { auth, setAuth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const updateUser = (toSign) => {
    const local = JSON.parse(localStorage.getItem("user"));
    local.has_documents_to_sign = toSign;
    localStorage.setItem("user", JSON.stringify(local));
    setAuth({
      user: local,
    });
  };

  const handleSign = () => {
    let linkSign = `/merchant/documents/${dataList[idx].id}/versions/${dataList[idx].versions[0].id}/files/${current.id}/sign/`;
    const { email } = jwt_decode(auth?.user?.access);
    axiosPrivate
      .post(linkSign, {
        user: email,
      })
      .then((response) => {
        const { data } = response.data;
        dataList[idx]?.versions[0]?.files.map((file) => {
          if (file.id === current.id) {
            file.data_sign = data.dataSign;
            dataList[idx].versions[0].status =
              data.dataSign.status === 2 ? "Signed" : "Pending";
            updateUser(data.to_sign);
            setDataList(dataList);
            toast.success(t("Document signed"));
            setModalOpen(false);
          }
          return "";
        });
      })
      .catch((response) => {
        if (response?.status === 401) {
          localStorage.removeItem("user");
          return setAuth({});
        }
        if (!response.data) {
          console.log(response);
          return;
        }
        const errors = parseErrors(response);
        if (typeof errors === "string") {
          toast.error(t(errors));
        } else {
          toast.error(t("Something went wrong"));
        }
      });
  };
  const markRead = () => {
    let linkSign = `/merchant/documents/${dataList[idx].id}/versions/${dataList[idx].versions[0].id}/files/${current.id}/read/`;
    const { email } = jwt_decode(auth?.user?.access);
    axiosPrivate
      .post(linkSign, {
        user: email,
      })
      .then((response) => {
        const { data } = response.data;
        dataList[idx]?.versions[0]?.files.map((file) => {
          if (file.id === current.id) {
            file.data_sign = data.dataSign;
            dataList[idx].versions[0].status =
              data.dataSign.status === 1 ? "Read" : "Pending";
            setDataList(dataList);
          }
          return "";
        });
      })
      .catch((response) => {
        if (response?.status === 401) {
          localStorage.removeItem("user");
          return setAuth({});
        }
        if (!response.data) {
          console.log(response);
          return;
        }
        const errors = parseErrors(response);
        if (typeof errors === "string") {
          toast.error(t(errors));
        } else {
          toast.error(t("Something went wrong"));
        }
      });
  };
  if (dataList[idx]?.document_type === 1 && !current.data_sign) markRead();
  return (
    <Modal
      open={modalOpen}
      onClose={() => {
        setModalOpen(false);
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "45%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80vw",
          height: "80vh",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        {idx < 0 ? (
          <>
            <object data={current.url} width="100%" height="85%">
              <embed src={current.url} width="100%" height="85%"></embed>
            </object>
          </>
        ) : (
          <>
            {current.url ? (
              <object data={current.url} width="100%" height="85%">
                <embed src={current.url} width="100%" height="85%"></embed>
              </object>
            ) : (
              "cargando"
            )}
            {dataList[idx]?.document_type === 2 ? (
              current.data_sign ? (
                <Typography
                  style={{ float: "right" }}
                  fontFamily='"Roboto","Helvetica","Arial",sans-serif'
                  color={colors.grey}
                  sx={{
                    mt: {
                      xs: "0px",
                      md: "0.6rem",
                    },
                  }}
                >
                  {current.name}
                  <br />
                  <DatetimeValue
                    text={t("Signed at: ")}
                    date={current.data_sign?.created_at}
                  />
                </Typography>
              ) : (
                <Typography
                  style={{ float: "right" }}
                  variant="h6"
                  fontFamily='"Roboto","Helvetica","Arial",sans-serif'
                  mb={1}
                  sx={{
                    mt: {
                      xs: "0px",
                      md: "1rem",
                    },
                  }}
                >
                  {t("I have read and ")}
                  &nbsp;
                  <Button
                    onClick={handleSign}
                    size="small"
                    variant="contained"
                    startIcon={<AssignmentTurnedInIcon />}
                    className="base__btn-action"
                  >
                    {t("agree terms and Conditions")}
                  </Button>
                </Typography>
              )
            ) : (
              <></>
            )}
          </>
        )}
      </Box>
    </Modal>
  );
}
