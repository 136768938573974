import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import Pagination from "../../Pagination/Pagination";
import TableBackdrop from "../../TableUI/TableBackdrop";
import DatetimeValue from "../../TableUI/DatetimeValue";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IconButton, Link, Typography } from "@mui/material";

const GetFileByLanguage = (files, language) => {
  const chossen = files.filter(
    (file) => file.language.toLowerCase() === language
  );
  return chossen.length ? chossen[0] : files[0];
};
export default function VersionTable({
  data,
  pages = 1,
  page = 1,
  loading = false,
  handleOnPageChange,
  viewVersions,
  document_type,
  language,
}) {
  const { t } = useTranslation();

  return (
    <>
      <Paper
        sx={{
          width: {
            xs: "calc(100vw - 80px)",
            sm: "calc(100vw - 330px)",
          },
          overflow: "hidden",
        }}
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{t("State")}</TableCell>
                <TableCell>{t("Version")}</TableCell>
                <TableCell>{t("Deadline")}</TableCell>
                <TableCell>{t("Published")}</TableCell>
                <TableCell>{t("Signed")}</TableCell>
                <TableCell align="right">&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length ? (
                data.map((row, idx) => (
                  <TableRow
                    key={idx}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>
                      {document_type === 2 && idx === 0 ? (
                        <>
                          {row.files[0] && row.files[0].signed ? (
                            <>
                              <Typography>{t("Signed")}</Typography>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Link
                        underline="hover"
                        color="blue"
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          viewVersions(GetFileByLanguage(row.files, language))
                        }
                      >
                        {row.version}
                      </Link>
                    </TableCell>
                    <TableCell align="center">
                      {row.deadline ? (
                        <DatetimeValue date={row.deadline} withOutTime={true} />
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell>
                      <DatetimeValue
                        date={row.files[0]?.published_at}
                        withOutTime={true}
                      />
                    </TableCell>
                    <TableCell>
                      <DatetimeValue
                        date={row.files[0]?.dataSign?.created_at}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        onClick={() => {
                          viewVersions(GetFileByLanguage(row.files, language));
                        }}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  {loading ? (
                    <TableBackdrop open={loading} />
                  ) : (
                    <TableCell sx={{ textAlign: "center" }} colSpan={100}>
                      {t("No records found")}
                    </TableCell>
                  )}
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Pagination
        handleOnPageChange={handleOnPageChange}
        page={page}
        pages={pages}
      />
    </>
  );
}

export { GetFileByLanguage };
