import { Grid } from '@mui/material'
import { Formik, Form } from 'formik'
import TextfieldWrapper from '../../FormUI/Textfield/TextfieldWrapper'
import SelectApiWrapper from '../../FormUI/SelectWrapper/SelectApiWrapper'
import { Box } from '@mui/system'
import { useTranslation } from 'react-i18next'


export default function CompanyForm({
    initialFormState,
    handleSubmit,
    countries
}) {
    const { t } = useTranslation()

    return (
        <>
            <Formik
                initialValues={{
                    ...initialFormState
                }}
                onSubmit={handleSubmit}
            >
                <Form>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextfieldWrapper
                                name="company_name"
                                label={t('Company')}
                                type="text"
                                disabled={true}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextfieldWrapper
                                name="fantasy_name"
                                label={t('Fantasy name')}
                                type="text"
                                disabled={true}
                            />
                        </Grid>

                        <Box width="100%" />

                        <Grid item xs={12} md={2}>
                            <SelectApiWrapper
                                name="company_country"
                                label={t('Country')}
                                options={countries}
                                disabled={true}
                                translate={false}
                            />
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <TextfieldWrapper
                                name="phone_number"
                                label={t('Phone number')}
                                type="text"
                                disabled={true}
                            />
                        </Grid>
{/* 
                        <Grid item xs={12} md={3}>
                            <TextfieldWrapper
                                name="company_type"
                                label={t('Company type')}
                                type="text"
                                disabled={true}
                            />
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <TextfieldWrapper
                                name="industry"
                                label={t('Industry')}
                                type="text"
                                disabled={true}
                            />
                        </Grid> */}

                        <Grid item xs={12} md={7}>
                            <TextfieldWrapper
                                name="website"
                                label={t('Website')}
                                type="text"
                                disabled={true}
                            />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <TextfieldWrapper
                                name="company_description"
                                label={t('Company description')}
                                type="text"
                                disabled={true}
                            />
                        </Grid>
                    </Grid>
                </Form>
            </Formik>
        </>
    )
}

// company_name, company_type, company_description, phone_number, country, website, industry