import { Grid, Alert } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import TextfieldWrapper from '../../FormUI/Textfield/TextfieldWrapper';
import SubmitWrapper from '../../FormUI/SubmitWrapper/SubmitWrapper';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import SaveIcon from '@mui/icons-material/Save';


export default function PasswordForm({
  initialFormState,
  handleSubmit
}) {
  const { t } = useTranslation();

  const FORM_VALIDATION = Yup.object().shape({
    old_password: Yup.string()
      .required(t('Password is required'))
      .min(8, t('Password is too short - should be 8 chars minimum'))
      .matches(/[a-zA-Z0-9]/, t('Password can only contain letters and numbers')),
    password: Yup.string()
      .required(t('Password is required'))
      .min(8, t('Password is too short - should be 8 chars minimum'))
      .matches(/[a-zA-Z0-9]/, t('Password can only contain letters and numbers')),
    password_confirmation: Yup.string()
      .required(t('Password confirmation is required'))
      .oneOf([Yup.ref('password'), null], t('Passwords must match'))
  });

  return (
    <>
      <Formik
        initialValues={{
          ...initialFormState
        }}
        validationSchema={FORM_VALIDATION}
        onSubmit={handleSubmit}
      >
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Alert severity="info" icon={false}>
                <p>{t('Your Password must be a combination of alphanumeric characters of 8-16 character')}</p>
                <p>{t('Your new password cannot be the same as the old one')}</p>
              </Alert>
            </Grid>

            <Grid item xs={12} md={4}>
              <TextfieldWrapper
                name="old_password"
                label={t('Current password')}
                type="password"
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextfieldWrapper
                name="password"
                label={t('Password')}
                type="password"
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextfieldWrapper
                name="password_confirmation"
                label={t('Password confirmation')}
                type="password"
              />
            </Grid>

            <Box width="100%" />

            <Grid item xs={12} md={4}>
              <SubmitWrapper startIcon={<SaveIcon />}>{t('Update')}</SubmitWrapper>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </>
  )
}
