import axios from "axios"

const PAYMENT_WINDOW_URL = 'https://paymentwindow.payretailers.cl'
const PAYMENT_WINDOW_URL_QA = 'https://paymentwindowqa.payretailers.cl'

/**
 * URL para servicio de CDN
 */
const CDN_URL = 'https://cdn.payretailers.cl/images/payretailers/'

// FIXME
const BASE_URL = 'https://paygolnewapiint.paygol.com/api/v2' // process.env.REACT_APP_BASE_URL

const CASHOUTS = 'https://cashouts.paygol.com/api/v1'

export default axios.create({
    baseURL: BASE_URL
})

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-type': 'application/json'
    }
})

export const axiosCashouts = axios.create({
    baseURL: CASHOUTS,
    headers: {
        'Content-type': 'application/json'
    }
})

export {
    BASE_URL,
    PAYMENT_WINDOW_URL,
    PAYMENT_WINDOW_URL_QA,
    CDN_URL
}