import { useState, useEffect } from "react";
import { Card, CardContent } from "@mui/material";
import { useTranslation } from "react-i18next";
import PageTitle from "../../Misc/PageTitle";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useAuth from "../../../hooks/useAuth";
import DocumentsTable from "./DocumentsTable";
import ModalDocument from "./ModalDocument";

export default function DocumentList() {
  const { t, i18n } = useTranslation();

  const { setAuth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [current, setCurrent] = useState([]);
  const [idx, setIdx] = useState(0);

  const [meta, setMeta] = useState({
    total_pages: 1,
    current_page: 1,
  });

  const [data, setData] = useState([]);

  const handleOnPageChange = (e, page) => {
    setMeta({ ...meta, current_page: page });
  };
  const viewDocument = (current, idx) => {
    setCurrent(current);
    setIdx(idx);
    setModalOpen(true);
  };

  useEffect(() => {
    let isSubscribed = true;
    const retrieveData = () => {
      axiosPrivate
        .get("/merchant/documents/")
        .then((response) => {
          if (isSubscribed) {
            const { data, meta } = response.data;
            setData(data);
            if (meta) {
              setMeta(meta);
            }
          }
        })
        .catch(({ response }) => {
          if (response?.status === 401) {
            localStorage.removeItem("user");
            return setAuth({});
          }

          if (isSubscribed) {
            toast.error(t("Something went wrong"));
          }
        })
        .finally(() => {
          setLoading(false);

          return () => (isSubscribed = false);
        });
    };

    retrieveData();
  }, [t, setAuth, axiosPrivate, i18n.language]);
  return (
    <>
      <Card>
        <CardContent>
          <PageTitle title={t("Documents")} />
          <DocumentsTable
            data={data}
            pages={meta.total_pages}
            page={meta.current_page}
            loading={loading}
            handleOnPageChange={handleOnPageChange}
            viewDocument={viewDocument}
            language={i18n.language}
          />
        </CardContent>
      </Card>
      {modalOpen && (
        <ModalDocument
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          setDataList={setData}
          dataList={data}
          current={current}
          idx={idx}
        />
      )}
    </>
  );
}
