import { useState } from 'react'
import { Button, Card, CardContent, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import { LoadingButton } from '@mui/lab'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { toast } from 'react-toastify'
import PageTitle from '../../Misc/PageTitle'
import ButtonBase from '@mui/material/ButtonBase'
import ClearIcon from '@mui/icons-material/Clear'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import useIsMounted from '../../../hooks/useIsMounted';
import { useEffect } from 'react'

const Input = styled('input')({
    display: 'none',
});

const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
})

export default function UploadLogo() {
    const { t } = useTranslation()

    const axiosPrivate = useAxiosPrivate()

    const isMounted = useIsMounted()

    const [loading, setLoading] = useState(false)

    const [file, setFile] = useState()

    const [image, setImage] = useState()

    const [hasImage, setHasImage] = useState(false)

    const handleChange = (event) => {
        setFile(event.target.files[0])

        const objectUrl = URL.createObjectURL(event.target.files[0])

        setImage(objectUrl)
    }

    const handleResetFile = () => {
        setFile(null)
        setImage(null)
    }

    const handleUploadFile = () => {
        setLoading(true)

        axiosPrivate.put('/merchant/me/logo/', file, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Content-Disposition': 'inline; filename="MiArchivo"'
            }
        })
            .then(({data: {data}}) => {
                handleResetFile()
                setImage(data)
                setHasImage(true)
                toast.success(t('Your company logo has been updated'))
            })
            .catch(() => isMounted() && toast.error(t('Something went wrong')))
            .finally(() => isMounted() && setLoading(false))
    }

    const handleDeleteLogo = () => {
        axiosPrivate.delete('/merchant/me/logo/')
            .then(() => {
                toast.success(t('Your company logo has been deleted'))
                setHasImage(false)
                handleResetFile()
            })
    }

    useEffect(() => {
        const getLogo = () => {
            axiosPrivate.get('/merchant/me/logo/')
                .then(({ data: { data } }) => {
                    if (isMounted() && data) {
                        setHasImage(true)
                        setImage(data)
                    }
                })
        }

        getLogo()
    }, [axiosPrivate, isMounted])

    return (
        <Card>
            <CardContent>

                <PageTitle title={t('Upload company logo')} />

                {!image && (<label htmlFor="contained-button-file">
                    <Input
                        accept="image/png,image/jpeg"
                        onChange={handleChange}
                        type="file"
                        id="contained-button-file"
                    />
                    {!file && (<Button variant="contained" component="span" sx={{
                        width: {
                            xs: '100%',
                            md: 'auto'
                        }
                    }}>
                        Seleccionar archivo
                    </Button>)}
                </label>)}

                <Grid container spacing={2} justifyContent="center">
                    {image && (<Grid item>
                        <ButtonBase sx={{ width: 200, height: 200 }}>
                            {image && (<Img alt="Paygol" src={image} />)}
                        </ButtonBase>
                    </Grid>)}
                    {file && (<Grid item xs={12} sm container>
                        <Grid item xs container direction="column" spacing={2}>
                            <Grid item xs mt={2.5}>
                                <Typography variant="body2" color="text.secondary">
                                    {file.name}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Tamaño: {Math.round(file.size / 1000)}KB
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>)}
                    <Grid item xs={12}>
                        {!!file && (<>
                            <LoadingButton
                                variant="contained"
                                loading={loading}
                                onClick={handleUploadFile}
                                loadingPosition="start"
                                startIcon={<UploadFileIcon />}
                                size="small"
                                disabled={loading}
                                sx={{
                                    width: {
                                        xs: '100%',
                                        md: 'auto'
                                    }
                                }}
                            >
                                {t('Upload logo')}
                            </LoadingButton>

                            <Button
                                variant="outlined"
                                color="error"
                                onClick={handleResetFile}
                                size="small"
                                sx={{
                                    mx: {
                                        md: 2
                                    },
                                    my: 1,
                                    width: {
                                        xs: '100%',
                                        md: 'auto'
                                    }
                                }}
                                startIcon={<ClearIcon />}>{t('Remove file')}</Button>
                        </>)}

                        {
                            hasImage && (<Button
                                variant="outlined"
                                color="error"
                                onClick={handleDeleteLogo}
                                size="small"
                                sx={{
                                    mx: {
                                        md: 2
                                    },
                                    my: 1,
                                    width: {
                                        xs: '100%',
                                        md: 'auto'
                                    }
                                }}
                                startIcon={<ClearIcon />}>{t('Remove file')}</Button>)
                        }
                    </Grid>
                </Grid>
            </CardContent>
        </Card >
    )
}
