import { useRef, useState } from 'react'
import { Grid, Stack, Typography, Button } from '@mui/material'
import { Formik, Form } from 'formik'
import TextfieldWrapper from '../../FormUI/Textfield/TextfieldWrapper'
import ButtonWrapper from '../../FormUI/ButtonWrapper/ButtonWrapper'
import { Box } from '@mui/system'
import { useTranslation } from 'react-i18next'
import SubmitWrapper from '../../FormUI/SubmitWrapper/SubmitWrapper'
import CloseIcon from '@mui/icons-material/Close'
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useNavigate } from 'react-router-dom'
import SelectWrapper from '../../FormUI/SelectWrapper/SelectWrapper'
import GenericWrapper from "../../FormUI/GenericWrapper/GenericWrapper";
import { currencies } from '../../../enums/PaymentLinks'


export function Base64FIleField({ ...otherProps }) {
    const { t } = useTranslation()

    const [file, setFile] = useState(null)

    const uploadFile = () => {
        const input = document.createElement('input')
        input.type = 'file'
        input.accept = 'image/*'
        input.onchange = (e) => {
            const file = e.target.files[0]
            const reader = new FileReader()
            reader.onloadend = () => {
                setFile(file)
                // Extract the data part of the url, which is the base64 encoded file contents
                const base64Data = reader.result.split(',')[1]
                otherProps.onChange({ target: { value: base64Data }, file: file })
            }
            reader.readAsDataURL(file)
        }
        input.click()
    }

    const error = otherProps.helperText

    return <Stack spacing={0.1}>
        <div style={{ fontSize: "0.6rem" }}>{otherProps.label}</div>
        <Button
            variant={file ? "contained" : "outlined"}
            color="primary"
            onClick={uploadFile}
            startIcon={<UploadFileIcon />}
        >
            {t(file ? "Change file" : "Choose a file")}
        </Button>
        {file && <Typography>{file.name}</Typography>}
        {error && <Typography style={{ fontSize: "0.6rem", color: "red" }}>{error}</Typography>}

    </Stack>
}

export default function PaymentLinksForm({
    isNew,
    handleSubmit,
    initialFormState,
    FORM_VALIDATION
}) {
    const { t } = useTranslation()

    const ref = useRef(null)

    const navigate = useNavigate()

    return (
        <Formik
            innerRef={ref}
            initialValues={{
                ...initialFormState
            }}
            validationSchema={FORM_VALIDATION}
            onSubmit={handleSubmit}
        >
            {({ values }) => (
                <Form>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant='h5'>{t('Payment links')}</Typography>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextfieldWrapper
                                name="title"
                                label={t('Title')}
                                type="text"
                                inputProps={{
                                    maxLength: 64
                                }}
                            />
                        </Grid>

                        <Box width="100%" />

                        <Grid item xs={8} md={3}>
                            <TextfieldWrapper
                                name="amount"
                                label={t('Amount')}
                                type="number"
                            />
                        </Grid>

                        <Grid item xs={4} md={2}>
                            <SelectWrapper
                                name="currency"
                                label={t('Currency')}
                                options={currencies}
                                translate={false}
                            />
                        </Grid>

                        <Box width="100%" />

                        <Grid item xs={12}>
                            <TextfieldWrapper
                                name="details"
                                multiline
                                rows={2}
                                label={t('Details')}
                                inputProps={{
                                    maxLength: 1500
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <GenericWrapper
                                name={"image"}
                                WrappedComponent={Base64FIleField}
                            />
                        </Grid>
                        <Box width="100%" />

                        <Grid item xs={12}>
                            <Stack direction="row" spacing={2}>
                                <SubmitWrapper sx={{
                                        width: {
                                            xs: '100%',
                                            md: 'auto'
                                        }
                                    }}>{t('Save')}</SubmitWrapper>
                                <ButtonWrapper
                                    variant="text"
                                    onClick={() => navigate('/payment-links')}
                                    sx={{
                                        width: {
                                            xs: '100%',
                                            md: 'auto'
                                        }
                                    }}
                                    startIcon={<CloseIcon />}>{t('Cancel')}</ButtonWrapper>
                            </Stack>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>

    )
}
