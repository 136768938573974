import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@mui/material'
import { useField, useFormikContext } from 'formik';
import React from 'react'

export default function CheckboxWrapper({
    name,
    label,
    legend = '',
    ...otherProps
}) {
    const { setFieldValue } = useFormikContext();

    const [field, meta] = useField(name);

    const handleChange = event => {
        const { checked } = event.target;
        setFieldValue(name, checked);
    };

    const configCheckbox = {
        ...field,
        ...otherProps,
        checked: field.value,
        onChange: handleChange
    }

    const configFormControl = {};

    if (meta && meta.touched && meta.error) {
        configFormControl.error = true;
    }

    return (
        <FormControl {...configFormControl}>
            {legend ? <FormLabel component="legend">{legend}</FormLabel> : ''}
            <FormGroup>
                <FormControlLabel
                    control={<Checkbox {...configCheckbox} />}
                    label={label}
                />

            </FormGroup>
        </FormControl>
    )
}
