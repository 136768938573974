import { useEffect, useState } from 'react'
import UserForm from './UserForm'
import { Card, CardContent } from '@mui/material'
import FormSkeletonWrapper from '../../SkeletonUI/FormSkeletonWrapper/FormSkeletonWrapper'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { parseErrors } from '../../../services/utils'
import PageTitle from '../../Misc/PageTitle'
import useAxiosPrivate from "../../../hooks/useAxiosPrivate"
import useAuth from "../../../hooks/useAuth"


export default function UserCreate() {
    const { t } = useTranslation()

    const { setAuth } = useAuth()
    const axiosPrivate = useAxiosPrivate()

    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(true)

    const handleSubmit = (values, { setFieldError }) => {
        axiosPrivate.post('/merchant/users/', values)
            .then(() => {
                toast.success(t('User has been created'))
                navigate('/users')
            })
            .catch(({ response }) => {
                if (response?.status === 401) {
                    localStorage.removeItem('user')
                    return setAuth({})
                }

                const errors = parseErrors(response)

                if (typeof errors === 'object') {
                    for (let i in errors) {
                        setFieldError(errors[i].field, t(errors[i].message), false)
                    }
                } else if (typeof errors === 'string') {
                    toast.error(t(errors))
                } else {
                    toast.error(t('Something went wrong'))
                }
            })
    }

    const handleCancel = () => {
        navigate('/users')
    }

    const initialFormState = {
        email: '',
        language: '',
        user_type: '',
        password: '',
        password_confirmation: ''
    }

    const FORM_VALIDATION = Yup.object().shape({
        email: Yup.string()
            .email(t('You must enter a valid email address'))
            .required(t('This field is required')),
        language: Yup.string()
            .required(t('This field is required')),
        user_type: Yup.number()
            .required(t('This field is required')),
        password: Yup.string()
            .required(t('This field is required'))
            .min(8, t('Password is too short - should be 8 chars minimum'))
            .matches(/[a-zA-Z0-9]/, t('Password can only contain letters and numbers')),
        password_confirmation: Yup.string()
            .required(t('This field is required'))
            .oneOf([Yup.ref('password'), null], t('Passwords must match'))
    })

    useEffect(() => {
        setTimeout(() => setIsLoading(false), 1000)
    }, [])

    return (
        <>
            <Card>
                <CardContent>
                    <PageTitle title={t('Add a new user account')} />

                    {
                        isLoading ? <FormSkeletonWrapper /> :
                            <UserForm
                                isNew={true}
                                handleSubmit={handleSubmit}
                                handleCancel={handleCancel}
                                initialFormState={initialFormState}
                                FORM_VALIDATION={FORM_VALIDATION}
                            />
                    }
                </CardContent>
            </Card>
        </>
    )
}
