import React from 'react';
import { Grid, Typography, Skeleton, Box } from '@mui/material';


export default function FormSkeletonWrapper() {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
                <Typography variant="h4"><Skeleton /></Typography>
            </Grid>

            <Box width="100%" />

            <Grid item xs={12} md={3}>
                <Typography variant="h2"><Skeleton /></Typography>
            </Grid>

            <Grid item xs={12} md={4}>
                <Typography variant="h2"><Skeleton /></Typography>
            </Grid>

            <Grid item xs={12} md={8}>
                <Typography variant="h2"><Skeleton /></Typography>
            </Grid>

            <Grid item xs={12} md={4}>
                <Typography variant="h2"><Skeleton /></Typography>
            </Grid>

            <Grid item xs={12} md={4}>
                <Typography variant="h2"><Skeleton /></Typography>
            </Grid>
        </Grid>
    )
}
