import React from 'react';
import { useField, useFormikContext } from 'formik';
import { useEffect } from 'react';

export default function GenericWrapper({
    name,
    WrappedComponent,
    onChange = () => { },
    ...otherProps
}) {
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(name);

    useEffect(() => {
        if (otherProps.value !== undefined) {
            setFieldValue(name, otherProps.value)
        }
    }, [otherProps.value, setFieldValue, name])


    const handleChange = event => {
        onChange(event);
        const { value } = event.target;
        setFieldValue(name, value);
    };

    const configTextfiel = {
        ...field,
        ...otherProps,
        //fullWidth: true,
        //variant: 'outlined',
        onChange: handleChange
    };

    if (meta && meta.touched && meta.error) {
        configTextfiel.error = true;
        configTextfiel.helperText = meta.error;
    }

    return (
        <WrappedComponent {...configTextfiel} />
    )
}
