import { Alert, AlertTitle, Button, Card, CardContent, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import BackdropWrapper from '../../BackdropWrapper/BackdropWrapper'
import Confirmation from '../../Misc/Confirmation'
import PageTitle from '../../Misc/PageTitle'
import CurrencyValue from '../../TableUI/CurrencyValue'
import DatetimeValue from '../../TableUI/DatetimeValue'
import WithdrawalsCard from './WithdrawalsCard'

export default function WithdrawalsMobile({
    methods,
    summary,
    amount,
    currency,
    handleRequest,
    allowWithdrawal = false,
    min_amount = 0,
    loading = false,
    requesting = false,
    open,
    setOpen,
    days = 7,
    pending
}) {
    const { t } = useTranslation()

    const handleConfirm = () => {
        handleRequest()
            .then(() => setOpen(false))
    }

    return (
        <>
            {loading && (<BackdropWrapper open={loading} />)}

            {
                pending ?
                    <Stack sx={{ width: '100%' }} spacing={2} mb={4}>
                        <Alert severity="info" variant="outlined" mb={4}>
                            <AlertTitle><Typography variant='h6'>{t('You have a withdrawal in progress')}</Typography></AlertTitle>
                            <DatetimeValue text={t('Request date')} date={pending.created_at} />
                            <CurrencyValue text={t('Amount')} amount={pending.withdrawable_amount} currency={pending.currency} />
                        </Alert>
                    </Stack> : ''
            }

            <Grid container spacing={2} justifyContent="center">
                {methods.filter(m => m.total > 0).map((m, idx) => (
                    <Grid item xs={12} key={idx}>
                        <WithdrawalsCard
                            method={m}
                            currency={currency}
                        />
                    </Grid>
                ))}

                {allowWithdrawal ? (
                    <>
                        <Grid item xs={12}>
                            <PageTitle title={t('Details')} />
                        </Grid>

                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    {methods.filter(m => m.available > 0).map((m, idx) => (<Grid container spacing={2} key={idx}>
                                        <Grid item xs={6}>
                                            <Typography gutterBottom variant="subtitle2" component="div">
                                                {m.name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                            <Typography gutterBottom variant="subtitle2" component="div">
                                                <CurrencyValue amount={m.available} currency={currency} />
                                            </Typography>
                                        </Grid>
                                    </Grid>))}

                                    <Grid item xs={12} sx={{ my: 2 }} />

                                    {summary.settlement_cost > 0 && (<Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Typography gutterBottom variant="subtitle2" component="div">
                                                {t('Settlement cost')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                            <Typography gutterBottom variant="subtitle2" component="div">
                                                <CurrencyValue amount={summary.settlement_cost * -1} currency={currency} />
                                            </Typography>
                                        </Grid>
                                    </Grid>)}

                                    {summary.chargebacks_fee > 0 && (<Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Typography gutterBottom variant="subtitle2" component="div">
                                                {t('Chargebacks')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                            <Typography gutterBottom variant="subtitle2" component="div">
                                                <CurrencyValue amount={summary.chargebacks_fee * -1} currency={currency} />
                                            </Typography>
                                        </Grid>
                                    </Grid>)}

                                    {summary.refunds_fee > 0 && (<Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Typography gutterBottom variant="subtitle2" component="div">
                                                {t('Refunds')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                            <Typography gutterBottom variant="subtitle2" component="div">
                                                <CurrencyValue amount={summary.refunds_fee * -1} currency={currency} />
                                            </Typography>
                                        </Grid>
                                    </Grid>)}

                                    {summary.cashouts > 0 && (<Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Typography gutterBottom variant="subtitle2" component="div">
                                                {t('Cashouts')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                            <Typography gutterBottom variant="subtitle2" component="div">
                                                <CurrencyValue amount={summary.cashouts * -1} currency={currency} />
                                            </Typography>
                                        </Grid>
                                    </Grid>)}

                                    {summary.other_charges > 0 && (<Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Typography gutterBottom variant="subtitle2" component="div">
                                                {t('Other charges')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                            <Typography gutterBottom variant="subtitle2" component="div">
                                                <CurrencyValue amount={summary.other_charges * -1} currency={currency} />
                                            </Typography>
                                        </Grid>
                                    </Grid>)}

                                    {summary.other_credits > 0 && (<Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Typography gutterBottom variant="subtitle2" component="div">
                                                {t('Other credits')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                            <Typography gutterBottom variant="subtitle2" component="div">
                                                <CurrencyValue amount={summary.other_credits * -1} currency={currency} />
                                            </Typography>
                                        </Grid>
                                    </Grid>)}

                                    <Grid item xs={12} sx={{ my: 2 }} />

                                    {summary && summary.withdrawable_amount ? (<Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Typography gutterBottom variant="subtitle2" component="div">
                                                {t('Total')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                            <Typography gutterBottom variant="subtitle2" component="div">
                                                <CurrencyValue amount={summary.withdrawable_amount} currency={currency} />
                                            </Typography>
                                        </Grid>
                                    </Grid>) : ''}
                                </CardContent>
                            </Card>
                        </Grid>
                    </>
                ) : ''}

            </Grid>

            <Stack spacing={2} direction="row" justifyContent="flex-end" mt={4}>
                <Button
                    variant="outlined"
                    onClick={() => setOpen(true)}
                    fullWidth
                    disabled={!allowWithdrawal}
                >{t('Request payout')}</Button>
            </Stack>

            {pending && (<Stack spacing={2} direction="row" justifyContent="flex-end" mt={4}><Typography variant='subtitle2' align='center'>{t('Remember that once the payment in progress is finished, you can request the withdrawal of your money again.')}</Typography></Stack>)}

            {allowWithdrawal ? <>
                <Confirmation
                    open={open}
                    title={t('Funds withdrawal confirmation')}
                    legend={
                        <>
                            <Typography
                                component='div'
                                sx={{
                                    display: 'block'
                                }}
                            >{
                                    t('Please confirm your withdrawal request for an amount of {{amount}}', { amount: new Intl.NumberFormat('es-CL', { style: 'currency', currency: currency }).format(summary.withdrawable_amount) })
                                }</Typography>

                            <Typography
                                variant='subtitle2'
                                component='div'
                                sx={{
                                    pt: 3,
                                    textAlign: 'center'
                                }}
                            >{
                                    t('Remember that you will receive your money within a maximum period of {{days}} business days', { days: days })
                                }</Typography>
                        </>
                    }
                    handleConfirm={handleConfirm}
                    handleClose={() => setOpen(false)}
                    requesting={requesting}
                /></> : ''
            }
        </>
    )
}
