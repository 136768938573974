import { CircularProgress, Container, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

export default function RedirectPage() {
    const { t } = useTranslation()

    React.useEffect(() => {
        window.location.replace('https://paygol.atlassian.net/servicedesk/customer/portals');
    }, [])

    return (
        <Container maxWidth="sm" sx={{
            textAlign: 'center',
            mt: '90px'
        }}>
            <CircularProgress color="secondary" />

            <Typography variant="overline" sx={{
                m: 2
            }} component="div">
                {t('Redirecting...')}
            </Typography>
        </Container>
    )
}
