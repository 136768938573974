const ST_ACTIVE = 1
const ST_PAUSED = 2
const ST_CLOSED = 3

const currencies = {
    CLP: 'CLP',
    USD: 'USD',
    EUR: 'EUR'
}

const status = {
    1: 'Active',
    2: 'Paused',
    3: 'Closed',
}

export {
    ST_ACTIVE,
    ST_PAUSED,
    ST_CLOSED,
    currencies,
    status
}