import { useEffect } from 'react'
import { Card, CardContent } from '@mui/material'
import { useState } from 'react'
import FormSkeletonWrapper from '../../SkeletonUI/FormSkeletonWrapper/FormSkeletonWrapper'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import EmailInvoicesForm from './EmailInvoicesForm'
import { toast } from 'react-toastify'
import { addDaysToDate, getFormatedDate, parseErrors, replaceNullValues } from '../../../services/utils'
import useAxiosPrivate from "../../../hooks/useAxiosPrivate"
import useAuth from "../../../hooks/useAuth"


export default function EmailInvoicesEdit() {
   const { t } = useTranslation()

   const { setAuth } = useAuth()
   const axiosPrivate = useAxiosPrivate()

   const navigate = useNavigate()

   const { invoiceId } = useParams()

   const [loading, setLoading] = useState(true)

   const [emailInvoice, setEmailInvoice] = useState()

   const handleCancel = () => {
      navigate('/email-invoices')
   }

   const handleSubmit = (values, { setFieldError }) => {
      values.due_date = new Date(values.due_date)

      axiosPrivate.put(`/merchant/email-invoices/${invoiceId}/`, values)
         .then(() => {
            toast.success(t('Your Email Invoice has been updated'))
            navigate('/email-invoices')
         })
         .catch(({ response }) => {
            if (response?.status === 401) {
               localStorage.removeItem('user')
               return setAuth({})
            }

            const errors = parseErrors(response)

            if (typeof errors === 'object') {
               for (let i in errors) {
                  setFieldError(errors[i].field, t(errors[i].message), false)
               }
            } else if (typeof errors === 'string') {
               toast.error(t(errors))
            } else {
               toast.error(t('Something went wrong'))
            }
         })
   }

   const today = new Date()

   const max_date = () => {
      const max_date = new Date((new Date()).setDate(today.getDate() + 365))
      return getFormatedDate(max_date)
   }

   const FORM_VALIDATION = Yup.object().shape({
      due_date: Yup.date(t('Due date must be a valid date'))
         .required(t('This field is required'))
         .min(new Date(), t('Due date must be after today'))
         .max(max_date(), t('Due date cannot exceed {{days}} days', { days: 365 })),
      title: Yup.string()
         .required(t('This field is required'))
         .max(64, t('This field must contain a maximum of {{max}} characters', { max: 32 })),
      currency: Yup.string()
         .required(t('This field is required'))
         .test('len', t('This field must be a valid currency code'), val => val.length === 3),
      details: Yup.string()
         .max(512, t('This field must contain a maximum of {{max}} characters', { max: 512 })),
      client_email: Yup.string()
         .email(t('You must enter a valid email address'))
         .required(t('Email address is required')),
      client: Yup.string()
         .required(t('This field is required')),
   })

   const due_date = (date) => {
      const due_date = new Date(date)

      return getFormatedDate(addDaysToDate(due_date, 1))
   }

   useEffect(() => {
      const getUser = () => {
         axiosPrivate.get(`/merchant/email-invoices/${invoiceId}/`)
            .then(({ data: { data } }) => {
               if (!data.title) {
                  data.title = ''
               }

               data.due_date = due_date(data.due_date)

               setEmailInvoice(replaceNullValues(data))
            })
            .catch(({ response }) => {
               if (response?.status === 401) {
                  localStorage.removeItem('user')
                  return setAuth({})
               }

               toast.error(t('Something went wrong'))
            })
            .finally(() => setLoading(false))
      }

      getUser()
   }, [invoiceId, t, axiosPrivate, setAuth])

   return (
      <>
         <Card>
            <CardContent>
               {
                  loading ? <FormSkeletonWrapper /> :
                     <EmailInvoicesForm
                        isNew={false}
                        handleSubmit={handleSubmit}
                        handleCancel={handleCancel}
                        initialFormState={emailInvoice}
                        FORM_VALIDATION={FORM_VALIDATION}
                     />
               }
            </CardContent>
         </Card>
      </>
   )
}
