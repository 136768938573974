import { useNavigate } from 'react-router-dom'
import { IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';



export default function BackButton() {
    const navigate = useNavigate()
    return (
        <IconButton onClick={() => navigate(-1)}><ArrowBackIosIcon /></IconButton>
    )
}