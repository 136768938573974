import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import useAxiosPrivate from "../../../hooks/useAxiosPrivate"
import CheckIcon from '@mui/icons-material/Check'
import TableBackdrop from '../../TableUI/TableBackdrop'
import { Card, Grid, Hidden, Typography } from '@mui/material';
import DatetimeValue from '../../TableUI/DatetimeValue'
import useAuth from '../../../hooks/useAuth'
import { toast } from 'react-toastify'


export default function BankAccountsForm() {
  const { t } = useTranslation()

  const { setAuth } = useAuth()

  const [loading, setLoading] = useState(true)

  const axiosPrivate = useAxiosPrivate()

  const [accounts, setAccounts] = useState([])

  useEffect(() => {
    const fetchData = () => {
      axiosPrivate.get(`/merchant/me/bank-accounts/`)
        .then(({ data: { data } }) => {
          setAccounts(data)
        })
        .catch(({ response }) => {
          if (response?.status === 401) {
            localStorage.removeItem('user')
            return setAuth({})
          }

          toast.error(t('Something went wrong'))
        })
        .finally(() => setLoading(false))
    }

    fetchData()
  }, [axiosPrivate, setAuth, t])

  // bank_name, bank_country, account_owner, account_owner_personal_id, swift_bic, iban, is_default, status, is_intermediary, notification_email, created_at, updated_at
  // account_number, account_type,
  return (
    <>
      <Hidden mdUp={true}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Card variant="outlined" sx={{
              p: 2
            }}>
              <Typography variant="h6">{accounts[0]?.bank_name}</Typography>
              <Typography variant="subtitle1">{accounts[0]?.bank_country}</Typography>
              <Typography variant="subtitle1">{accounts[0]?.account_number}</Typography>
              <Typography variant="subtitle1">{accounts[0]?.account_type}</Typography>

              <Typography variant="subtitle1" sx={{ mt: 2 }}>{accounts[0]?.account_owner}</Typography>
              <Typography variant="subtitle1">{accounts[0]?.account_owner_personal_id}</Typography>
            </Card>

          </Grid>
        </Grid>
      </Hidden>

      <Hidden mdDown={true}>
        <Paper sx={{
          width: {
            xs: 'calc(100vw - 80px)',
            sm: 'calc(100vw - 330px)'
          }, overflow: 'hidden'
        }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('Bank name')}</TableCell>
                  <TableCell>{t('Account number')}</TableCell>
                  <TableCell>{t('Account owner')}</TableCell>
                  <TableCell>{t('Swift/BIC')}</TableCell>
                  <TableCell>{t('IBAN')}</TableCell>
                  <TableCell align="center">{t('Status')}</TableCell>
                  <TableCell align="center">{t('Default')}</TableCell>
                  <TableCell align="center">{t('Intermediary')}</TableCell>
                  <TableCell>{t('Notification email')}</TableCell>
                  <TableCell align="right">{t('Last update')}</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {accounts.length && !loading ? accounts.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>
                      <Typography noWrap>{row.bank_name}</Typography>
                      <Typography>{row.bank_country}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{row.account_number}</Typography>
                      <Typography>{row.account_type}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography noWrap>{row.account_owner}</Typography>
                      <Typography noWrap>{row.account_owner_personal_id}</Typography>
                    </TableCell>
                    <TableCell>{row.swift_bic}</TableCell>
                    <TableCell>{row.iban}</TableCell>
                    <TableCell align="center">{t(row.status_)}</TableCell>
                    <TableCell align="center">
                      {row.is_default && (<CheckIcon />)}
                    </TableCell>
                    <TableCell align="center">
                      {row.is_intermediary && (<CheckIcon />)}
                    </TableCell>
                    <TableCell>
                      <Typography noWrap>{row.notification_email}</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <DatetimeValue date={row.updated_at} />
                    </TableCell>
                  </TableRow>
                )) : <TableRow>
                  {
                    loading ? <TableBackdrop open={loading} /> : <TableCell sx={{ textAlign: 'center' }} colSpan={100}>{t('No records found')}</TableCell>
                  }
                </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Hidden>


      {/* <Formik
        initialValues={{
          ...initialFormState
        }}
        onSubmit={handleSubmit}
      >
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <TextfieldWrapper
                name="bank_name"
                label={t('Bank name')}
                type="text"
                disabled={true}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <TextfieldWrapper
                name="bank_country"
                label={t('Bank country')}
                type="text"
                disabled={true}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <TextfieldWrapper
                name="account_number"
                label={t('Account number')}
                type="text"
                disabled={true}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <TextfieldWrapper
                name="account_type"
                label={t('Account type')}
                type="text"
                disabled={true}
              />
            </Grid>

            <Box width="100%" />

            <Grid item xs={12} md={4}>
              <TextfieldWrapper
                name="account_owner"
                label={t('Account owner')}
                type="text"
                disabled={true}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <TextfieldWrapper
                name="account_owner_personal_id"
                label={t('Document number')}
                type="text"
                disabled={true}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <TextfieldWrapper
                name="swift_bic"
                label={t('Swift/BIC')}
                type="text"
                disabled={true}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <TextfieldWrapper
                name="iban"
                label={t('IBAN')}
                type="text"
                disabled={true}
              />
            </Grid>

          </Grid>
        </Form>
      </Formik> */}
    </>
  )
}
