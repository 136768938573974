import { useEffect, useState, useCallback } from 'react'
import { Card, CardContent } from '@mui/material'
import { useTranslation } from 'react-i18next'
import FormSkeletonWrapper from '../../SkeletonUI/FormSkeletonWrapper/FormSkeletonWrapper'
import CredentialsForm from './CredentialsForm'
import { toast } from 'react-toastify'
import PageTitle from '../../Misc/PageTitle'
import { parseErrors } from '../../../services/utils'
import { replaceNullValues } from './../../../services/utils'
import useAxiosPrivate from "../../../hooks/useAxiosPrivate"
import useAuth from "../../../hooks/useAuth"


export default function Credentials() {
    const [loading, setLoading] = useState(true)

    const { setAuth } = useAuth()
    const axiosPrivate = useAxiosPrivate()

    const [credentials, setCredentials] = useState({
        public_key: '',
        secret_key: '',
        service_id: '',
        old_uuid: ''
    })

    const { t } = useTranslation()

    const getApiKey = useCallback(() => {
        axiosPrivate.get('/merchant/api-keys/')
            .then(({ data: { data } }) => {
                setCredentials(replaceNullValues(data))
                setLoading(false)
            })
            .catch(({ response }) => {
                if (response?.status === 401) {
                    localStorage.removeItem('user')
                    return setAuth({})
                }

                toast.error(t('Something went wrong'))
            })
            .finally(() => setLoading(false))
    }, [t, setAuth, axiosPrivate])

    const handleRenew = () => {
        axiosPrivate.post('/merchant/api-keys/', {})
            .then(({ data: { data } }) => {
                setCredentials(data)
                toast.success(t('Your credentials have been updated!!'))
            })
            .catch(({ response }) => {
                if (response?.status === 401) {
                    localStorage.removeItem('user')
                    return setAuth({})
                }

                const errors = parseErrors(response)

                if (typeof errors === 'string') {
                    toast.error(t(errors))
                } else {
                    toast.error(t('Something went wrong'))
                }
            })
    }

    useEffect(() => {
        const init = () => {
            getApiKey()
        }

        init()
    }, [getApiKey])

    return (
        <>
            <Card>
                <CardContent>
                    <PageTitle title={t('Credentials')} />

                    {
                        loading ? <FormSkeletonWrapper /> :
                            <CredentialsForm
                                handleRenew={handleRenew}
                                initialFormState={credentials}
                            />
                    }
                </CardContent>
            </Card>
        </>
    )
}
