import { TableCell } from '@mui/material'
import BackdropWrapper from '../BackdropWrapper/BackdropWrapper'

export default function TableBackdrop({
    open
}) {
    return (
        <>
            <TableCell colSpan={100}>
                <BackdropWrapper open={open} />
            </TableCell>
        </>
    )
}
