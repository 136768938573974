import { Typography, Grid, Paper } from '@mui/material'

export default function KeyValue({
    title,
    value,
    ...otherProps
}) {
    return (
        <Grid item {...otherProps}>
            <Paper elevation={3} sx={{
                padding: '20px'
            }}>
                <Typography variant='h6'>{title}</Typography>
                <Typography variant='body1'>{value}</Typography>
            </Paper>
        </Grid>
    )
}
