const ROLE_OWNER = 2
const ROLE_ADMIN = 3
const ROLE_DEV = 4
const ROLE_ACCOUNTING = 5
const ROLE_RO = 6

export {
    ROLE_OWNER,
    ROLE_ADMIN,
    ROLE_DEV,
    ROLE_ACCOUNTING,
    ROLE_RO
}