import { useState, useEffect } from "react";
import { Card, CardContent } from "@mui/material";
import { useTranslation } from "react-i18next";
import PageTitle from "../../Misc/PageTitle";
import useAuth from "../../../hooks/useAuth";
import VersionsTable from "./VersionsTable";
import { useLocation } from "react-router-dom";
import ModalDocument from "./ModalDocument";

export default function VersionList() {
  const { state } = useLocation();
  const { document } = state;
  const { t, i18n } = useTranslation();
  const { setAuth } = useAuth();
  const [current, setCurrent] = useState([]);
  const [loading, setLoading] = useState(true);

  const [meta, setMeta] = useState({
    total_pages: 1,
    current_page: 1,
  });

  const [data, setData] = useState([]);

  const handleOnPageChange = (e, page) => {
    setMeta({ ...meta, current_page: page });
  };
  const [modalOpen, setModalOpen] = useState(false);
  const viewVersions = (version) => {
    setCurrent(version);
    setModalOpen(true);
  };

  useEffect(() => {
    document.versions.shift();
    setData(document.versions);
    setLoading(false);
  }, [t, setAuth, i18n.language, document.versions]);
  return (
    <>
      <Card>
        <CardContent>
          <PageTitle title={document.name} />
          <VersionsTable
            data={data}
            setData={setData}
            pages={meta.total_pages}
            page={meta.current_page}
            loading={loading}
            handleOnPageChange={handleOnPageChange}
            viewVersions={viewVersions}
            document_type={document.document_type}
            language={i18n.language}
          />
        </CardContent>
      </Card>
      {modalOpen && (
        <ModalDocument
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          setDataList={setData}
          dataList={data}
          current={current}
          idx={-1}
        />
      )}
    </>
  );
}
