const ST_CREATED = 1
const ST_COMPLETED = 2
const ST_FAILED = 3
const ST_CHARGEBACK = 4
const ST_REFUND = 5
const ST_ON_HOLD = 6

const status = {
    1: 'Created',
    2: 'Completed',
    3: 'Failed',
    4: 'Chargeback',
    5: 'Refund'
}

export {
    ST_CREATED,
    ST_COMPLETED,
    ST_FAILED,
    ST_CHARGEBACK,
    ST_REFUND,
    ST_ON_HOLD,
    status
}