import { useEffect, useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useTranslation } from 'react-i18next'
import Pagination from '../../Pagination/Pagination'
import TableActions from '../../TableUI/TableActions'
import EditIcon from '@mui/icons-material/Edit'
import { useNavigate } from 'react-router-dom'
import TableBackdrop from '../../TableUI/TableBackdrop'
import VisibilityIcon from '@mui/icons-material/Visibility'
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead'
import CurrencyValue from '../../TableUI/CurrencyValue'
import DatetimeValue from '../../TableUI/DatetimeValue'
import { ST_EXPIRED, ST_PAID } from '../../../enums/EmailInvoices'
import DateValue from '../../TableUI/DateValue'
import { Card, CardContent, CardHeader, Grid, Hidden, Typography } from '@mui/material'
import BackdropWrapper from '../../BackdropWrapper/BackdropWrapper'
import { BASE_URL, PAYMENT_WINDOW_URL_QA, PAYMENT_WINDOW_URL } from '../../../api'
import PillBadge from '../../TableUI/PillBadge';


export default function EmailInvoicesTable({
    data,
    pages = 1,
    page = 1,
    loading = false,
    handleOnPageChange,
    handleNotification
}) {
    const { t } = useTranslation()

    const navigate = useNavigate()

    const [paymentWindowUrl, setPaymentWindowUrl] = useState()

    const handleNavigate = (view) => {
        navigate(view)
    }

    const handleClick = (token) => {
        window.open(`${paymentWindowUrl}/email-invoice/${token}`, "_blank", 'noopener')
    }

    useEffect(() => {
        setPaymentWindowUrl(
            /gateway/.test(BASE_URL) ? PAYMENT_WINDOW_URL : PAYMENT_WINDOW_URL_QA
        )
    }, [])

    return (
        <>
            <Hidden mdUp={true}>
                <Grid container spacing={.5} justifyContent="center">
                    {
                        data.length && !loading ? data.map(d => (
                            <Grid item xs={12} key={d.id}>
                                <Card>
                                    <CardHeader
                                        action={
                                            <TableActions actions={[
                                                {
                                                    id: `preview-${d.id}`,
                                                    handleClick: () => { handleClick(d.token) },
                                                    icon: <VisibilityIcon />,
                                                    title: t('Preview')
                                                },
                                                {
                                                    id: `edit-${d.id}`,
                                                    handleClick: () => { handleNavigate(`edit/${d.id}`) },
                                                    icon: <EditIcon />,
                                                    title: t('Edit'),
                                                    disabled: d.status === ST_PAID || d.status === ST_EXPIRED || new Date(d.due_date) < (new Date())
                                                },
                                                {
                                                    id: `email-${d.id}`,
                                                    handleClick: () => handleNotification(d.id),
                                                    icon: <MarkEmailReadIcon />,
                                                    title: t('Send'),
                                                    disabled: d.status === ST_PAID || d.status === ST_EXPIRED || new Date(d.due_date) < (new Date())
                                                }
                                            ]} />
                                        }
                                        title={<Typography variant="h6">{d.title}</Typography>}
                                        subheader={
                                            <DatetimeValue date={d.created_at} />
                                        }
                                    />
                                    <CardContent>
                                        <Typography variant='div' sx={{
                                            textAlign: 'right'
                                        }}>
                                            <CurrencyValue
                                                amount={d.amount}
                                                currency={d.currency}
                                            />
                                        </Typography>
                                        <Typography>{d.client}</Typography>
                                        <Typography>{d.client_email}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        )) : (loading ? <BackdropWrapper open={loading} /> : 'no')
                    }
                </Grid>
            </Hidden>

            <Hidden mdDown={true}>
                <Paper sx={{
                    width: {
                        xs: 'calc(100vw - 80px)',
                        sm: 'calc(100vw - 330px)'
                    }, overflow: 'hidden'
                }}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">{t('Status')}</TableCell>
                                    <TableCell>{t('Title')}</TableCell>
                                    <TableCell>{t('Client')}</TableCell>
                                    <TableCell align="right">{t('Amount')}</TableCell>
                                    <TableCell align="center">{t('Currency')}</TableCell>
                                    <TableCell align="center">{t('Created at')}</TableCell>
                                    <TableCell align="center">{t('Sent at')}</TableCell>
                                    <TableCell align="center">{t('Paid at')}</TableCell>
                                    <TableCell align="center">{t('Due date')}</TableCell>
                                    <TableCell align="right">&nbsp;</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.length && !loading ? data.map((row) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="center">
                                            <PillBadge
                                                text={new Date(row.due_date) < (new Date()) ? t('Expired') : t(row.status_)}
                                                action={new Date(row.due_date) < (new Date()) ? 'danger' : (row.status_ === 'Paid' ? 'success' : row.status_ === 'Created' ? 'warning' : 'primary')}
                                            />
                                        </TableCell>
                                        <TableCell>{row.title}</TableCell>
                                        <TableCell>
                                            <Typography>{row.client}</Typography>
                                            <Typography>{row.client_email}</Typography>
                                        </TableCell>
                                        <TableCell align="right">
                                            <CurrencyValue
                                                amount={row.amount}
                                                currency={row.currency}
                                            />
                                        </TableCell>
                                        <TableCell align="center">{row.currency}</TableCell>
                                        <TableCell align="center">
                                            <DatetimeValue date={row.created_at} />
                                        </TableCell>
                                        <TableCell align="center">
                                            {row.sent_at && (<DatetimeValue date={row.sent_at} />)}
                                        </TableCell>
                                        <TableCell align="center">
                                            {row.paid_at && (<DatetimeValue date={row.paid_at} />)}
                                        </TableCell>
                                        <TableCell align="center">
                                            <DateValue date={row.due_date} timezone='UTC' />
                                        </TableCell>
                                        <TableCell align="right">
                                            <TableActions actions={[
                                                {
                                                    id: `preview-${row.id}`,
                                                    handleClick: () => { handleClick(row.token) },
                                                    icon: <VisibilityIcon />,
                                                    title: t('Preview')
                                                },
                                                {
                                                    id: `edit-${row.id}`,
                                                    handleClick: () => { handleNavigate(`edit/${row.id}`) },
                                                    icon: <EditIcon />,
                                                    title: t('Edit'),
                                                    disabled: row.status === ST_PAID || row.status === ST_EXPIRED
                                                },
                                                {
                                                    id: `email-${row.id}`,
                                                    handleClick: () => handleNotification(row.id),
                                                    icon: <MarkEmailReadIcon />,
                                                    title: t('Send'),
                                                    disabled: row.status === ST_PAID || row.status === ST_EXPIRED
                                                }
                                            ]} />
                                        </TableCell>
                                    </TableRow>
                                )) : <TableRow>
                                    {
                                        loading ? <TableBackdrop open={loading} /> : <TableCell sx={{ textAlign: 'center' }} colSpan={100}>{t('No records found')}</TableCell>
                                    }
                                </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Hidden>

            <Pagination
                handleOnPageChange={handleOnPageChange}
                page={page}
                pages={pages}
            />
        </>
    )
}
