import { Typography } from '@mui/material'

export default function DateValue({
    date,
    text = '',
    timezone = 'America/Santiago'
}) {
    return (
        <Typography noWrap>
            {text} {date && new Intl.DateTimeFormat('es-CL', {
                dateStyle: 'medium',
                timeZone: timezone
            }).format(new Date(date))}
        </Typography>
    )
}
