import React from 'react'
import { Grid, Skeleton } from '@mui/material'

export default function AvatarSkeleton() {
    return (
        <Grid container spacing={2}>
            <Grid item xs={3} md={2}>
                <Skeleton animation="wave" variant="circular" width={40} height={40} />
            </Grid>
            <Grid item xs={9} md={10}>
                <Skeleton
                    animation="wave"
                    height={20}
                    style={{ marginBottom: 6 }}
                />
                <Skeleton
                    animation="wave"
                    height={50}
                    style={{ marginBottom: 6 }}
                />
            </Grid>
        </Grid>
    )
}
