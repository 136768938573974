import { useState, useEffect } from 'react'
import { Button, Grid } from '@mui/material'
import { Link } from 'react-router-dom'

import AddIcon from '@mui/icons-material/Add'
import { Card, CardContent } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Filters from './Filters'
import AppFilters from '../../AppFilters'
import PaymentLinksTable from './PaymentLinksTable'
import { useCallback } from 'react'
import { toast } from 'react-toastify'
import useAxiosPrivate from "../../../hooks/useAxiosPrivate"
import useAuth from "../../../hooks/useAuth"
import PageTitle from '../../Misc/PageTitle'
import useIsMounted from '../../../hooks/useIsMounted'
import PaymentLinksModal from './PaymentLinksModal'
import PaymentLinksShareModal from './PaymentLinksShareModal'

export default function PaymentLinksList() {
    const { t } = useTranslation()

    const isMounted = useIsMounted()

    const { setAuth } = useAuth()

    const axiosPrivate = useAxiosPrivate()

    const [loading, setLoading] = useState(true)

    const [sending, setSending] = useState(false)

    const [open, setOpen] = useState(false)

    const [shareOpen, setShareOpen] = useState(false)

    const [shareURL, setShareURL] = useState(null)

    const [current, setCurrent] = useState()

    const [params, setParams] = useState({})

    const [data, setData] = useState([])

    const [meta, setMeta] = useState({
        total_pages: 1,
        current_page: 1
    })

    const handleOnSearch = values => {
        if (values === params) {
            return false
        }

        setLoading(true)
        setSending(true)
        setParams(values)
    }

    const handleOnPageChange = (e, page) => {
        setMeta({ ...meta, current_page: page })
        setParams({ ...params, page: page })
    }

    const retrieveData = useCallback(() => {
        axiosPrivate.get('/merchant/payment-links/', { params: params })
            .then(({ data: { data, meta } }) => {
                setMeta(meta)
                setData(data)
            })
            .catch(({ response }) => {
                if (response?.status === 401) {
                    localStorage.removeItem('user')
                    return setAuth({})
                }

                toast.error(t('Something went wrong'))
            })
            .finally(() => setLoading(false))

    }, [params, t, setAuth, axiosPrivate])

    const handleNotification = (id, emails) => {
        setSending(true)
        return axiosPrivate.post(`/merchant/payment-links/${id}/emails/`, {
            emails: emails.split(',')
        })
            .then(() => {
                toast.success(t('Your Payment Link has been sent!'))
                handleClose()
            })
            .catch(({ response }) => {
                if (response?.status === 401) {
                    localStorage.removeItem('user')
                    return setAuth({})
                }

                toast.error(t('Something went wrong'))
            })
            .finally(() => isMounted() && setSending(false))
    }

    const handleClose = () => {
        setCurrent(null)
        setOpen(false)
    }

    const handleOpen = (id) => {
        setCurrent(id)
        setOpen(true)
    }

    const handleShareOpen = (shareURL) =>{
        setShareOpen(true)
        setShareURL(shareURL)
    }

    const handleShareClose = () =>{
        setShareOpen(false)
        setShareURL(null)
    }

    useEffect(() => {
        const loadData = () => {
            retrieveData()
        }

        loadData()
    }, [params, retrieveData])

    return (
        <>
            <Card>
                <CardContent>
                    <PageTitle title={t('Payment links')} />

                    <Grid container spacing={2} mt={2} mb={2}>
                        <Grid item xs={12}>
                            <Button
                                component={Link}
                                to="create"
                                size="small"
                                variant="contained"
                                startIcon={<AddIcon />}
                                className="base__btn-action"
                            >
                                {t('New')}
                            </Button>
                        </Grid>
                    </Grid>

                    <AppFilters Filters={<Filters handleOnSearch={handleOnSearch} />} />

                    <PaymentLinksTable
                        data={data}
                        pages={meta.total_pages}
                        page={meta.current_page}
                        loading={loading}
                        sending={sending}
                        handleOnPageChange={handleOnPageChange}
                        handleNotification={handleNotification}
                        handleOpen={handleOpen}
                        handleShareOpen={handleShareOpen}
                    />
                </CardContent>
            </Card>
            <PaymentLinksShareModal
                open={shareOpen}
                handleClose={handleShareClose}
                shareURL={shareURL}
            />

            <PaymentLinksModal
                open={open}
                current={current}
                handleClose={handleClose}
                handleSendEmails={handleNotification}
                sending={sending}
            />
        </>
    )
}
