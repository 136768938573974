import { useState, useEffect } from 'react'

import { Card, CardContent } from '@mui/material'
import { useTranslation } from 'react-i18next'
import MethodsTable from './MethodsTable'
import PageTitle from '../../Misc/PageTitle'
import { toast } from 'react-toastify';
import AppFilters from '../../AppFilters'
import Filters from './Filters'
import { ST_PRODUCTION, ST_DISABLED } from './../../../enums/PaymentMethods'
import useAxiosPrivate from "../../../hooks/useAxiosPrivate"
import useAuth from "../../../hooks/useAuth"
import { json_countries } from '../../../enums/Countries'


export default function MethodsList() {
    const { t, i18n } = useTranslation()

    const { setAuth } = useAuth()
    const axiosPrivate = useAxiosPrivate()

    const [loading, setLoading] = useState(true)

    const [countries, setCountries] = useState([])

    const [meta, setMeta] = useState({
        total_pages: 1,
        current_page: 1
    })

    const [data, setData] = useState({
        original: [],
        filtered: []
    })

    const handleOnPageChange = (e, page) => {
        setMeta({ ...meta, current_page: page })
    }

    const handleReset = () => {
        setData({
            ...data, filtered: data.original
        })
    }

    const handleOnSearch = (values) => {
        values = {
            ...values,
            country: String(values.country).replace('all', ''),
            status: String(values.status).replace('all', ''),
            name: String(values.name).replace('all', ''),
        }

        const regex = new RegExp(values.country)
        const regex_name = new RegExp(values.name, 'i')

        const filtered = data.original.filter(d => (
            regex.test(d.countries) && regex_name.test(d.name) && (values.status === '' ? d.status !== -1 : parseInt(d.status) === parseInt(values.status))
        ))

        setData({
            ...data, filtered
        })
    }

    const handleToggleStatus = (params) => {
        return axiosPrivate.post(`/merchant/payment-methods/${params.code}/switch-status/`, {})
            .then((response) => {
                const message = response.data.data.status === ST_PRODUCTION ? 'Payment method has been enabled' :
                    response.data.data.status === ST_DISABLED ? 'Payment method has been disabled' : 'Payment method has been requested'

                const original = data.original.map(m => {
                    if (m.api_code === response.data.data.api_code) {
                        m.status = response.data.data.status
                        m.status_ = response.data.data.status_
                    }

                    return m
                })

                const filtered = data.filtered.map(m => {
                    if (m.api_code === response.data.data.api_code) {
                        m.status = response.data.data.status
                        m.status_ = response.data.data.status_
                    }

                    return m
                })

                setData({
                    original, filtered
                })

                toast.success(t(message))
            })
            .catch(({ response }) => {
                if (response?.status === 401) {
                    localStorage.removeItem('user')
                    return setAuth({})
                }

                toast.error(t('Something went wrong'))
            })
    }

    useEffect(() => {
        const current_language = String(i18n.language).toUpperCase()
        const name_col = current_language === 'ES' ? 'name_es' : 'name_en'

        let isSubscribed = true

        const retrieveData = () => {
            axiosPrivate.get('/merchant/payment-methods/')
                .then((response) => {
                    if (isSubscribed) {
                        const { data, meta } = response.data

                        const countries = data.reduce((a, b) => a + b.countries + ',', '')
                        const country_list = [...new Set(countries.split(','))].sort().filter(c => c)
                            .map(c => {
                                const country = json_countries.filter(ct => ct.code === c)[0]

                                return country ? {
                                    id: c,
                                    name: country[name_col]
                                } : null
                            }).filter(n => n)

                        setCountries(country_list)

                        setData({
                            original: data,
                            filtered: data,
                        })

                        if (meta) {
                            setMeta(meta)
                        }
                    }
                })
                .catch(({ response }) => {
                    if (response?.status === 401) {
                        localStorage.removeItem('user')
                        return setAuth({})
                    }

                    if (isSubscribed) {
                        toast.error(t('Something went wrong'))
                    }
                })
                .finally(() => {
                    setLoading(false)

                    return () => isSubscribed = false
                })
        }

        retrieveData()
    }, [t, setAuth, axiosPrivate, i18n.language])

    return (
        <>
            <Card>
                <CardContent>
                    <PageTitle title={t('Payment methods')} />

                    <AppFilters Filters={<Filters
                        handleOnSearch={handleOnSearch}
                        handleReset={handleReset}
                        countries={[
                            { id: 'all', name: t('All') }, ...countries
                        ]}
                        methods={data.original.map(m => ({
                            id: m.api_code,
                            name: m.name
                        }))}
                    />} />

                    <MethodsTable
                        data={data.filtered}
                        setData={setData}
                        pages={meta.total_pages}
                        page={meta.current_page}
                        loading={loading}
                        handleOnPageChange={handleOnPageChange}
                        handleToggleStatus={handleToggleStatus}
                    />
                </CardContent>
            </Card>
        </>
    )
}
