import { useEffect, useState } from 'react'
import { Card, CardContent } from '@mui/material'
import { useTranslation } from 'react-i18next'
import FormSkeletonWrapper from '../../SkeletonUI/FormSkeletonWrapper/FormSkeletonWrapper'
import ConfigForm from './ConfigForm'
import * as Yup from 'yup'
import PageTitle from '../../Misc/PageTitle'
import { useCallback } from 'react'
import { toast } from 'react-toastify'
import { parseErrors } from '../../../services/utils'
import useAxiosPrivate from "../../../hooks/useAxiosPrivate"
import useAuth from "../../../hooks/useAuth"
import { replaceNullValues } from './../../../services/utils'


export default function Config() {
    const { setAuth } = useAuth()
    const axiosPrivate = useAxiosPrivate()

    const [loading, setLoading] = useState(true)

    const [config, setConfig] = useState({})

    const { t } = useTranslation()

    const getConfig = useCallback(() => {
        axiosPrivate.get('/merchant/me/account-config/')
            .then(({ data: { data } }) => {
                setConfig(replaceNullValues(data))
                setLoading(false)
            })
            .catch(({ response }) => {
                if (response?.status === 401) {
                    localStorage.removeItem('user')
                    return setAuth({})
                }

                toast.error(t('Something went wrong'))
            })
            .finally(() => setLoading(false))
    }, [t, setAuth, axiosPrivate])

    const handleSubmit = (values, { setFieldError }) => {
        axiosPrivate.put('/merchant/me/account-config/', values)
            .then(() => toast.success(t('Configuration has been updated')))
            .catch(({ response }) => {
                if (response?.status === 401) {
                    localStorage.removeItem('user')
                    return setAuth({})
                }

                const errors = parseErrors(response)

                if (typeof errors === 'object') {
                    for (let i in errors) {
                        setFieldError(errors[i].field, t(errors[i].message), false)
                    }
                } else if (typeof errors === 'string') {
                    toast.error(t(errors))
                } else {
                    toast.error(t('Something went wrong'))
                }
            })
    }

    const FORM_VALIDATION = Yup.object().shape({
        notification_email: Yup.string()
            .email(t('You must enter a valid email address')),
        webhook_url: Yup.string(),
        ipn_version: Yup.number()
    })

    useEffect(() => {
        const init = () => {
            getConfig()
        }

        init()
    }, [getConfig])

    return (
        <>
            <Card>
                <CardContent>
                    <PageTitle title={t('Config')} />

                    {
                        loading ? <FormSkeletonWrapper /> :
                            <ConfigForm
                                handleSubmit={handleSubmit}
                                initialFormState={config}
                                FORM_VALIDATION={FORM_VALIDATION}
                            />
                    }
                </CardContent>
            </Card>
        </>
    )
}
