import { Box, Grid, Stack } from "@mui/material"
import { Form, Formik } from "formik"
import { useTranslation } from 'react-i18next'

import ButtonWrapper from "../../FormUI/ButtonWrapper/ButtonWrapper"
import SubmitWrapper from "../../FormUI/SubmitWrapper/SubmitWrapper"

import SelectWrapper from "../../FormUI/SelectWrapper/SelectWrapper"
import TextfieldWrapper from "../../FormUI/Textfield/TextfieldWrapper"
import SearchIcon from '@mui/icons-material/Search'
import UndoIcon from '@mui/icons-material/Undo'
import { useState, useEffect, useCallback } from 'react'
import { status } from "../../../enums/Transactions"
import useAxiosPrivate from "../../../hooks/useAxiosPrivate"
import useAuth from "../../../hooks/useAuth"
import { getFormatedDate } from "../../../services/utils"

import DateRangePickerWrapper from "../../FormUI/DateTimePickerWrapper/DateRangePickerWrapper"


function properNewDate(dateStr) {
    let parts = dateStr.split('-')
    return new Date(parts[0], parts[1] - 1, parts[2])
}


function addDays(date, days) {
    return new Date(date).setDate(date.getDate() + days)
}

export default function Filters({
    handleOnSearch
}) {
    const { t } = useTranslation()

    const { setAuth } = useAuth()

    const axiosPrivate = useAxiosPrivate()

    const [methods, setMethods] = useState([])

    const getMethods = useCallback(() => {
        axiosPrivate.get('/merchant/payment-methods/')
            .then(({ data: { data } }) => {
                setMethods(data.reduce((o, m) => Object.assign(o, { [m.api_code]: m.name }), {}))
            })
            .catch(({ response }) => {
                if (response?.status === 401) {
                    localStorage.removeItem('user')
                    return setAuth({})
                }
            })
    }, [axiosPrivate, setAuth])

    const today = new Date()
    const [toDate, setToDate] = useState(today)
    const [fromDate, setFromDate] = useState(addDays(today, -31))

    useEffect(() => {
        if (toDate - fromDate > 31 * 24 * 60 * 60 * 1000) {
            setToDate(addDays(new Date(fromDate), 31))
        }
    }, [fromDate, toDate, setToDate])

    const initialFormState = {
        transaction_id: '',
        status: '2',
        payment_method__api_code: '',
        price: '',
        status_date__date__gte: '',
        status_date__date__lte: '',
        customer__email: ''
    }

    useEffect(() => {
        const loadData = () => {
            getMethods()
        }

        loadData()
    }, [getMethods])

    return (
        <Formik
            initialValues={{
                ...initialFormState
            }}
            onSubmit={handleOnSearch}
        >
            {({ handleReset, handleSubmit }) => (<Form>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={5}>
                        <TextfieldWrapper
                            name="transaction_id"
                            label={t('Transaction ID')}
                            type="text"
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={8} md={5}>
                        <TextfieldWrapper
                            name="customer__email"
                            label={t('Customer email')}
                            type="text"
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={4} md={5}>
                        <TextfieldWrapper
                            name="price"
                            label={t('Price Sold')}
                            type="text"
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <SelectWrapper
                            name="status"
                            label={t('Status')}
                            options={{
                                ...status, 'all': 'All'
                            }}
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <SelectWrapper
                            name="payment_method__api_code"
                            label={t('Payment method')}
                            options={
                                { 'all': 'All', ...methods }
                            }
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <DateRangePickerWrapper
                            title={t("Date range, maximum 31 days")}
                            fromProps={{
                                name: 'status_date__date__gte',
                                label: t('From'),
                                onChange: (e) => setFromDate(properNewDate(e.target.value)),
                                value: getFormatedDate(new Date(fromDate)),
                                inputProps: {
                                    max: getFormatedDate(today)
                                },
                            }}
                            toProps={{
                                name: 'status_date__date__lte',
                                label: t('To'),
                                onChange: (e) => setToDate(properNewDate(e.target.value)),
                                value: getFormatedDate(new Date(toDate)),
                                inputProps: {
                                    min: getFormatedDate(new Date(fromDate)),
                                    max: getFormatedDate(new Date(addDays(new Date(fromDate), 31))),
                                },
                            }}
                        />
                    </Grid>


                    <Box width="100%" />

                    <Grid item xs={12}>
                        <Stack direction="row" spacing={2} justifyContent="flex-end">
                            <SubmitWrapper startIcon={<SearchIcon />}>{t('Search')}</SubmitWrapper>
                            <ButtonWrapper
                                onClick={() => {
                                    handleReset()
                                    handleSubmit()
                                }}
                                variant="text"
                                startIcon={<UndoIcon />}>{t('Reset')}</ButtonWrapper>
                        </Stack>
                    </Grid>
                </Grid>
            </Form>)}
        </Formik >
    )
}
