import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    toolbar: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'space-between'
    },
    logo: {
        color: 'gray',
    },
    navlist: {
        minWidth: '250px',
        maxWidth: '300px'
    },
    ulAvatar: {
        backgroundColor: '#ff0054',
        color: 'white'
    },
    navAvatar: {
        width: '35px',
        height: '35px',
        color: '#2196f3'
    },
    // Side nav
    drawerPaper: {
        width: '250px',
        marginTop: '65px'
    },
    wrapper: {
        marginLeft: '250px'
    }
}));
