import { Typography } from "@mui/material";

export default function DatetimeValue({
  date,
  text = "",
  timezone = "America/Santiago",
  withOutTime = false,
}) {
  if (withOutTime) {
    let dateToSeparete = new Date(date);
    let day = dateToSeparete.getDate();
    let month = dateToSeparete.getMonth() + 1;
    let year = dateToSeparete.getFullYear();
    if (day < 10) day = "0" + day;
    if (month < 10) month = "0" + month;
    let string = day + "-" + month + "-" + year;
    return <Typography noWrap>{string}</Typography>;
  } else {
    return (
      <Typography noWrap>
        {text}{" "}
        {date &&
          new Intl.DateTimeFormat("es-CL", {
            dateStyle: "medium",
            timeStyle: "short",
            timeZone: timezone,
          }).format(new Date(date))}
      </Typography>
    );
  }
}
