import { Alert, AlertTitle, Card, CardContent, Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import PageTitle from '../../Misc/PageTitle'
import CurrencyValue from '../../TableUI/CurrencyValue'
import DatetimeValue from '../../TableUI/DatetimeValue'
import WithdrawalsTable from './WithdrawalsTable'

export default function WithdrawalsDesktop({
    methods,
    summary,
    amount,
    currency,
    handleRequest,
    allowWithdrawal = false,
    min_amount = 0,
    loading = false,
    requesting = false,
    open,
    setOpen,
    days = 7,
    pending
}) {
    const { t } = useTranslation()

    return (
        <Card sx={{
            width: {
                xs: 'calc(100vw - 50px)',
                sm: 'calc(100vw - 330px)'
            }, overflow: 'hidden'
        }}>
            <CardContent>
                <PageTitle title={t('My money')} />

                {
                    pending ?
                        <Stack sx={{ width: '100%' }} spacing={2} mb={4}>
                            <Alert severity="info" variant="outlined" mb={4}>
                                <AlertTitle><Typography variant='h6'>{t('You have a withdrawal in progress')}</Typography></AlertTitle>
                                <DatetimeValue text={t('Request date')} date={pending.created_at} />
                                <CurrencyValue text={t('Amount')} amount={pending.withdrawable_amount} currency={pending.currency} />
                            </Alert>
                        </Stack> : ''
                }

                <WithdrawalsTable
                    mt={2}
                    methods={methods}
                    summary={summary}
                    amount={pending ? pending.withdrawable_amount : 0}
                    currency={currency}
                    allowWithdrawal={allowWithdrawal && !pending}
                    loading={loading}
                    requesting={requesting}
                    minimum={min_amount}
                    handleRequest={handleRequest}
                    days={days}
                    pending={pending}
                />

                {pending && <Typography variant='subtitle2' align='center'>{t('Remember that once the payment in progress is finished, you can request the withdrawal of your money again.')}</Typography>}
            </CardContent>
        </Card>
    )
}
