import { Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import CurrencyValue from '../../TableUI/CurrencyValue'

export default function WithdrawalsCard({
    method,
    currency
}) {
    const { t } = useTranslation()
    return (
        <Card>
            <CardActionArea>
                <CardMedia
                    component="img"
                    image={`https://cdn.payretailers.cl/images/methods/method_button_${String(method.api_code).toLowerCase()}.png?v=7`}
                    alt={method.name}
                    sx={{
                        m: '10px',
                        width: '30%'
                    }}
                />

                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={7}>
                            <Typography component="div" sx={{
                                fontSize: '11px',
                                fontStyle: 'italic'
                            }}>
                                {t('Available sales')}
                            </Typography>

                            <Typography component="div" sx={{
                                fontSize: '11px',
                                fontStyle: 'italic'
                            }}>
                                {t('Estimated sales')}
                            </Typography>

                            <Typography component="div" sx={{
                                fontSize: '11px',
                                fontStyle: 'italic'
                            }}>
                                {t('Withdrawable')}
                            </Typography>
                        </Grid>
                        <Grid item xs={5} sx={{ textAlign: 'right' }}>
                            <CurrencyValue
                                amount={method.gross > 0 ? method.gross : 0}
                                currency={currency}
                                sx={{
                                    fontSize: '11px',
                                    fontWeight: '600'
                                }}
                            />

                            <CurrencyValue
                                amount={method.total > 0 ? method.total : 0}
                                currency={currency}
                                sx={{
                                    fontSize: '11px',
                                    fontWeight: '600'
                                }}
                            />

                            <CurrencyValue
                                amount={method.available > 0 ? method.available : 0}
                                currency={currency}
                                sx={{
                                    fontSize: '11px',
                                    fontWeight: '600'
                                }}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}
