import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Trans, useTranslation } from 'react-i18next';
import { Typography } from '@mui/material'
import { blue } from '@mui/material/colors'

export default function AlertDialog({
    setOpen,
    isOpen = false
}) {
    const { t } = useTranslation()

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <div>
            <Dialog
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>
                    {t('Dear customer')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText my={2} sx={{
                        textAlign: 'justify'
                    }}>
                        <Trans i18nKey="paygolMsgLine1">We remind you that you must respond as soon as possible to the email <Typography component="span" sx={{
                            color: blue[400]
                        }}>helpdesk@paygol.com</Typography> our request for documentation to complete the Compliance process, within the next 10 calendar days.</Trans>
                    </DialogContentText>
                    <DialogContentText my={2}>
                        <Trans i18nKey="paygolMsgLine2">We look forward to the information! Thank you.</Trans>
                    </DialogContentText>
                    <DialogContentText mt={4}>
                        <Trans i18nKey="paygolMsgLine3">PayRetailers team</Trans>
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{
                    padding: '2rem'
                }}>
                    <Button onClick={handleClose} autoFocus>
                        {t('I understand')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}