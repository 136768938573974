import { Pagination as MuiPagination, Stack } from '@mui/material';

export default function Pagination({
    handleOnPageChange,
    pages,
    page
}) {
    return (
        <>
            {pages > 1 ? <Stack mt={2} spacing={2}>
                <MuiPagination
                    count={pages}
                    page={page}
                    shape="rounded"
                    onChange={handleOnPageChange}
                />
            </Stack> : ''}
        </>

    )
}
