import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CurrencyValue from '../../TableUI/CurrencyValue'

export default function EmailInvoicesUploadTable({
    data
}) {
    const { t } = useTranslation()

    return (
        <TableContainer component={Paper}>
            {data.length && (<Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>{t('Title')}</TableCell>
                        <TableCell>{t('Client')}</TableCell>
                        <TableCell align="right">{t('Amount')}</TableCell>
                        <TableCell align="center">{t('Currency')}</TableCell>
                        <TableCell align="center">{t('Status')}</TableCell>
                        {/* <TableCell align="right">&nbsp;</TableCell> */}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row) => (
                        <TableRow
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell>{row.title}</TableCell>
                            <TableCell>
                                <Typography>{row.client}</Typography>
                                <Typography>{row.client_email}</Typography>
                            </TableCell>
                            <TableCell align="right">
                                <CurrencyValue
                                    amount={row.amount}
                                    currency={row.currency}
                                />
                            </TableCell>
                            <TableCell align="center">{row.currency}</TableCell>
                            <TableCell align="center">
                                {t(row.status_)}
                            </TableCell>
                            {/* <TableCell align="right"></TableCell> */}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>)}
        </TableContainer>
    )
}
