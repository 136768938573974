import { Alert, Grid, Stack } from '@mui/material'
import React from 'react'
import { Formik, Form } from 'formik'
import TextfieldWrapper from '../../FormUI/Textfield/TextfieldWrapper'
import SelectWrapper from '../../FormUI/SelectWrapper/SelectWrapper'
import ButtonWrapper from '../../FormUI/ButtonWrapper/ButtonWrapper'
import { Box } from '@mui/system'
import { useTranslation } from 'react-i18next'
import SubmitWrapper from '../../FormUI/SubmitWrapper/SubmitWrapper'
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from 'react-router-dom'
import { languages, types } from '../../../enums/Users'


export default function UserForm({
    isNew,
    handleSubmit,
    initialFormState,
    FORM_VALIDATION
}) {
    const { t } = useTranslation()

    const navigate = useNavigate()

    return (
        <Formik
            initialValues={{
                ...initialFormState
            }}
            validationSchema={FORM_VALIDATION}
            onSubmit={handleSubmit}
        >
            <Form>
                <Grid container spacing={2}>
                    {isNew ? <Grid item xs={12}>
                        <Alert severity="info" icon={false}>
                            <p>{t('Your Password must be a combination of alphanumeric characters of 8-16 character')}</p>
                            <p>{t('Don\'t use easy-to-guess passwords')}</p>
                        </Alert>
                    </Grid> : ''}

                    <Grid item xs={12} md={2}>
                        <SelectWrapper
                            name="language"
                            label={t('Language')}
                            options={languages}
                        />
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <SelectWrapper
                            name="user_type"
                            label={t('User type')}
                            options={types}
                        />
                    </Grid>

                    <Box width="100%" />

                    <Grid item xs={12} md={8}>
                        <TextfieldWrapper
                            name="email"
                            label={t('Email address')}
                            type="email"
                        />
                    </Grid>

                    <Box width="100%" />

                    {
                        isNew ? <Grid item xs={12} md={4}>
                            <TextfieldWrapper
                                name="password"
                                label={t('Password')}
                                type="password"
                            />
                        </Grid> : ''
                    }

                    {
                        isNew ? <Grid item xs={12} md={4}>
                            <TextfieldWrapper
                                name="password_confirmation"
                                label={t('Password confirmation')}
                                type="password"
                            />
                        </Grid> : ''
                    }

                    <Box width="100%" />

                    <Grid item xs={12}>
                        <Stack direction="row" spacing={2}>
                            <SubmitWrapper>{t('Save')}</SubmitWrapper>
                            <ButtonWrapper
                                variant="text"
                                onClick={() => navigate('/users')}
                                startIcon={<CloseIcon />}>{t('Cancel')}</ButtonWrapper>
                        </Stack>
                    </Grid>
                </Grid>
            </Form>
        </Formik>
    )
}
