import { LoadingButton } from '@mui/lab';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

export default function Confirmation({
    open,
    title,
    legend = '',
    handleConfirm,
    handleClose,
    requesting
}) {
    const { t } = useTranslation()

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <Typography component="div">
                        {legend}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={requesting} fullWidth>{t('Cancel')}</Button>
                    <LoadingButton
                        onClick={handleConfirm}
                        autoFocus
                        disabled={requesting}
                        loading={requesting}
                        endIcon={<ChevronRightIcon />}
                        loadingPosition="end"
                        variant='contained'
                        fullWidth
                    >
                        {t('Confirm')}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}
