import { useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useTranslation } from 'react-i18next'
import TableBackdrop from '../../TableUI/TableBackdrop'
import WithdrawalsTableRow from './WithdrawalsTableRow'
import { Button, Typography } from '@mui/material'
import Confirmation from '../../Misc/Confirmation'
import Stack from '@mui/material/Stack'
import CurrencyValue from '../../TableUI/CurrencyValue'


export default function WithdrawalsTable({
    methods,
    summary,
    amount,
    currency,
    handleRequest,
    allowWithdrawal = false,
    minimum = 0,
    loading = false,
    requesting = false,
    days = 7,
    pending
}) {
    const { t } = useTranslation()

    const [open, setOpen] = useState(false)

    // const days = summary.country && summary.country === 'CL' ? 2 : 7

    const handleClose = () => {
        setOpen(false)
    }

    const handleConfirm = () => {
        handleRequest()
            .then(() => setOpen(false))
    }

    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('Method')}</TableCell>
                            <TableCell align="right">{t('Available sales')}</TableCell>
                            <TableCell align="right">{t('Estimated sales')}</TableCell>
                            <TableCell align="right">{t('Withdrawable')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {methods.length ? methods.filter(m => m.total > 0).map((row, idx) => (
                            <WithdrawalsTableRow
                                key={idx}
                                title={row.name}
                                total={row.total}
                                total_sales={row.gross}
                                available={row.available}
                                currency={currency}
                                country={row.country}
                            />
                        ))
                            : <TableRow>
                                {
                                    loading ? <TableBackdrop open={loading} /> : <TableCell sx={{ textAlign: 'center' }} colSpan={100}></TableCell>
                                }
                            </TableRow>
                        }
                    </TableBody>

                    <TableBody sx={{
                        fontSize: '100%'
                    }}>
                        <WithdrawalsTableRow
                            title={t('Subtotal')}
                            total={summary.total_payments}
                            available={summary.sub_total_withdrawable}
                            total_sales={summary.total_gross_payments}
                            currency={currency}
                            sx={{
                                'th, td': {
                                    fontSize: '14px',
                                    fontWeight: '800',
                                    paddingTop: '40px',
                                    borderBottom: 'none'
                                }
                            }}
                        />
                    </TableBody>

                    <TableBody sx={{
                        fontSize: '100%'
                    }}>
                        <TableRow>
                            <TableCell colSpan={2}></TableCell>
                            <TableCell align="right">
                                <Typography>{t('Settlement cost')}</Typography>
                                {summary.chargebacks_fee > 0 && <Typography>{t('Chargebacks')}</Typography>}
                                {summary.chargebacks_fee > 0 && <Typography>{t('Refunds')}</Typography>}
                                {summary.cashouts > 0 && <Typography>{t('Cashouts')}</Typography>}
                                {summary.other_charges > 0 && <Typography>{t('Other charges')}</Typography>}
                                {summary.other_credits > 0 && <Typography>{t('Other credits')}</Typography>}
                                <Typography variant="h5" my={2}>{t('Total')}</Typography>
                            </TableCell>
                            <TableCell align="right">
                                <CurrencyValue amount={summary.settlement_cost > 0 ? (summary.settlement_cost * (-1)) : 0} currency={currency} />
                                {summary.chargebacks_fee > 0 && <CurrencyValue amount={summary.chargebacks_fee * (-1)} currency={currency} />}
                                {summary.refunds_fees > 0 && <CurrencyValue amount={summary.refunds_fees * (-1)} currency={currency} />}
                                {summary.cashouts > 0 && <CurrencyValue amount={summary.cashouts * (-1)} currency={currency} />}
                                {summary.other_charges > 0 && <CurrencyValue amount={summary.other_charges * (-1)} currency={currency} />}
                                {summary.other_credits > 0 && <CurrencyValue amount={summary.other_credits} currency={currency} />}

                                <CurrencyValue
                                    amount={summary.withdrawable_amount ? summary.withdrawable_amount : 0}
                                    currency={currency}
                                    variant="h5" my={2}
                                />

                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <Stack spacing={2} direction="row" justifyContent="flex-end" mt={4}>
                <Button
                    variant="outlined"
                    onClick={() => setOpen(true)}
                    disabled={!allowWithdrawal}
                >{t('Request payout')}</Button>
            </Stack>

            {allowWithdrawal ? <>
                <Confirmation
                    open={open}
                    title={t('Funds withdrawal confirmation')}
                    legend={
                        <>
                            <Typography
                                variant='span'
                                sx={{
                                    display: 'block'
                                }}
                            >{
                                    t('Please confirm your withdrawal request for an amount of {{amount}}', { amount: new Intl.NumberFormat('es-CL', { style: 'currency', currency: currency }).format(summary.withdrawable_amount) })
                                }</Typography>
                            <Typography
                                variant='span'>{
                                    t('Remember that you will receive your money within a maximum period of {{days}} business days', { days: days })
                                }</Typography>
                        </>
                    }
                    handleConfirm={handleConfirm}
                    handleClose={handleClose}
                    requesting={requesting}
                /></> : ''
            }
        </>
    )
}
