import * as React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { Button, Stack, Hidden } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'


import {
    FacebookShareButton,
    LineShareButton,
    LinkedinShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookIcon,
    LineIcon,
    LinkedinIcon,
    TelegramIcon,
    TwitterIcon,
    WhatsappIcon,
} from "react-share";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        xs: '40%',
        md: 600
    },
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

export default function PaymentLinksShareModal({open, handleClose, shareURL}) {
    const { t } = useTranslation()

    return (
        <>
            <Hidden mdUp={true}>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Stack
                            direction={"row"}
                            justifyContent="center"
                            alignItems={"center"}
                            mb={1}
                        >
                            <Typography variant="subtitle1" sx={{
                                fontSize: '10px',
                                color: 'gray',
                                mt: 1.5,
                                align: 'center'
                            }}>
                                {t('Selecciona la aplicación por la que quieres compartir el link de pago')}.
                            </Typography>
                        </Stack>
                        <Stack
                            direction={"row"}
                            justifyContent="center"
                            alignItems={"center"}
                            spacing={2}
                        >
                            <FacebookShareButton
                                url={shareURL}
                                quote={"Paga ahora con Paygol"}
                                hashtag={"#paygol"}
                            >
                                <FacebookIcon size={40} round/>
                            </FacebookShareButton>
                            <LineShareButton
                                url={shareURL}
                            >
                                <LineIcon size={40} round/>
                            </LineShareButton>
                            <LinkedinShareButton
                                url={shareURL}
                            >
                                <LinkedinIcon size={40} round/>
                            </LinkedinShareButton>
                        </Stack>
                        <Stack
                            direction={"row"}
                            justifyContent="center"
                            alignItems={"center"}
                            spacing={2}
                        >
                            <TelegramShareButton
                                url={shareURL}
                            >
                                <TelegramIcon size={40} round/>
                            </TelegramShareButton>
                            <TwitterShareButton
                                url={shareURL}
                                quote={"Paga ahora con Paygol"}
                                hashtag={"#paygol"}
                            >
                                <TwitterIcon size={40} round/>
                            </TwitterShareButton>
                            <WhatsappShareButton
                                url={shareURL}
                                title={"Paga ahora con Paygol:"}
                            >
                                <WhatsappIcon size={40} round/>
                            </WhatsappShareButton>
                        </Stack>
                        <Stack
                            direction={"row"}
                            justifyContent="center"
                            alignItems={"center"}
                        >
                            <Typography variant="subtitle1" sx={{
                                fontSize: '8px',
                                color: 'gray',
                                mt: 1.5,
                                align: 'center'
                            }}>
                                {t('Recuerda siempre verificar que estas enviando el link correcto.')}.
                            </Typography>
                        </Stack>

                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={2}
                            mt={2}
                        >
                            <Button
                                variant="text"
                                onClick={() => handleClose()}
                                sx={{
                                    width: {
                                        xs: '100%',
                                        md: 'auto'
                                    }
                                }}
                                startIcon={<CloseIcon />}>{t('Cancel')}</Button>
                        </Stack>

                    </Box>
                </Modal>
            </Hidden>
            <Hidden mdDown={true}>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Stack
                            direction={"row"}
                            justifyContent="center"
                            alignItems={"center"}
                            mb={1}
                        >
                            <Typography variant="subtitle1" sx={{
                                fontSize: '15px',
                                color: 'gray',
                                mt: 1.5,
                                align: 'center'
                            }}>
                                {t('Selecciona la aplicación por la que quieres compartir el link de pago')}.
                            </Typography>
                        </Stack>
                        <Stack
                            direction={"row"}
                            justifyContent="center"
                            alignItems={"center"}
                            spacing={2}
                        >
                            <FacebookShareButton
                                url={shareURL}
                                quote={"Paga ahora con Paygol"}
                                hashtag={"#paygol"}
                            >
                                <FacebookIcon size={60} round/>
                            </FacebookShareButton>
                            <LineShareButton
                                url={shareURL}
                            >
                                <LineIcon size={60} round/>
                            </LineShareButton>
                            <LinkedinShareButton
                                url={shareURL}
                            >
                                <LinkedinIcon size={60} round/>
                            </LinkedinShareButton>
                        </Stack>
                        <Stack
                            direction={"row"}
                            justifyContent="center"
                            alignItems={"center"}
                            spacing={2}
                        >
                            <TelegramShareButton
                                url={shareURL}
                            >
                                <TelegramIcon size={60} round/>
                            </TelegramShareButton>
                            <TwitterShareButton
                                url={shareURL}
                                quote={"Paga ahora con Paygol"}
                                hashtag={"#paygol"}
                            >
                                <TwitterIcon size={60} round/>
                            </TwitterShareButton>
                            <WhatsappShareButton
                                url={shareURL}
                                title={"Paga ahora con Paygol:"}
                            >
                                <WhatsappIcon size={60} round/>
                            </WhatsappShareButton>
                        </Stack>
                        <Stack
                            direction={"row"}
                            justifyContent="center"
                            alignItems={"center"}
                        >
                            <Typography variant="subtitle1" sx={{
                                fontSize: '10px',
                                color: 'gray',
                                mt: 1.5,
                                align: 'center'
                            }}>
                                {t('Recuerda siempre verificar que estas enviando el link correcto.')}.
                            </Typography>
                        </Stack>

                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={2}
                            mt={2}
                        >
                            <Button
                                variant="text"
                                onClick={() => handleClose()}
                                sx={{
                                    width: {
                                        xs: '100%',
                                        md: 'auto'
                                    }
                                }}
                                startIcon={<CloseIcon />}>{t('Cancel')}</Button>
                        </Stack>

                    </Box>
                </Modal>
            </Hidden>
        </>
    )
}
