import { Box, Grid, Stack } from "@mui/material"
import { Form, Formik } from "formik"
import { useTranslation } from 'react-i18next'

import ButtonWrapper from "../../FormUI/ButtonWrapper/ButtonWrapper"
import SubmitWrapper from "../../FormUI/SubmitWrapper/SubmitWrapper"

import SearchIcon from '@mui/icons-material/Search'
import UndoIcon from '@mui/icons-material/Undo'
import DateTimePickerWrapper from '../../FormUI/DateTimePickerWrapper/DateTimePickerWrapper'
import { getFormatedDate } from "../../../services/utils"
import TextfieldWrapper from "../../FormUI/Textfield/TextfieldWrapper"
import SelectWrapper from '../../FormUI/SelectWrapper/SelectWrapper';

export default function FiltersCashouts({
    handleOnSearch
}) {
    const { t } = useTranslation()

    const today = new Date()

    const initialFormState = {
        from_date: '',
        to_date: '',
        str_payout_id: '',
        merchant_payout_id: '',
        status: 0
    }

    return (
        <Formik
            initialValues={{
                ...initialFormState
            }}
            onSubmit={handleOnSearch}
        >
            {({ handleReset, handleSubmit }) => (<Form>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <TextfieldWrapper
                            name="str_payout_id"
                            label={t('Cashout ID')}
                            type="text"
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <TextfieldWrapper
                            name="merchant_payout_id"
                            label={t('Merchant ID')}
                            type="text"
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <SelectWrapper
                            name="status"
                            label={t('Status')}
                            options={{
                                0: 'ALL', ...{
                                    1: 'CREATED',
                                    2: 'IN PROCESS',
                                    3: 'PAID',
                                    4: 'FAILED',
                                    5: 'REJECTED',
                                    6: 'CANCELED',
                                }
                            }}
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <DateTimePickerWrapper
                            name='from_date'
                            label={t('From date')}
                            size="small"
                            inputProps={{
                                max: getFormatedDate(today)
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <DateTimePickerWrapper
                            name='to_date'
                            label={t('To date')}
                            size="small"
                            inputProps={{
                                max: getFormatedDate(today)
                            }}
                        />
                    </Grid>

                    <Box width="100%" />

                    <Grid item xs={12}>
                        <Stack direction="row" spacing={2} justifyContent="flex-end">
                            <SubmitWrapper startIcon={<SearchIcon />}>{t('Search')}</SubmitWrapper>
                            <ButtonWrapper
                                onClick={() => {
                                    handleReset()
                                    handleSubmit()
                                }}
                                variant="text"
                                startIcon={<UndoIcon />}>{t('Reset')}</ButtonWrapper>
                        </Stack>
                    </Grid>
                </Grid>
            </Form>)}
        </Formik >
    )
}
