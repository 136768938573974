import { Typography } from '@mui/material'

export default function Pagetitle({
    title
}) {
    return (
        <Typography
            component="h1"
            style={{
                fontWeight: '500',
                fontSize: '1.3rem'
            }}
        >
            {title}
        </Typography>
    )
}
