import { useState, useEffect, useCallback } from 'react'

import { Card, CardContent } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Filters from './Filters'
import AppFilters from '../../AppFilters'
import SettlementsTable from './SettlementsTable'
import PageTitle from '../../Misc/PageTitle'
import { toast } from 'react-toastify'
import useAxiosPrivate from "../../../hooks/useAxiosPrivate"
import useAuth from "../../../hooks/useAuth"
import useIsMounted from '../../../hooks/useIsMounted';
import { createDowndloadFunction } from '../../../services/utils'


export default function SettlementsList() {
    const { t } = useTranslation()

    const [fetchingData, setFetchingData] = useState(false)

    const { setAuth } = useAuth()

    const isMounted = useIsMounted()

    const axiosPrivate = useAxiosPrivate()

    const [loading, setLoading] = useState(true)

    const [params, setParams] = useState({})

    const [meta, setMeta] = useState({
        total_pages: 1,
        current_page: 1
    })

    const [data, setData] = useState([])

    const handleOnSearch = values => {
        setLoading(true)

        setParams(values)
    }

    const handleOnPageChange = (e, page) => {
        setLoading(true)

        setMeta({ ...meta, current_page: page })
        setParams({ ...params, page: page })
    }

    const handleExportXlsx = (id) => createDowndloadFunction(`/merchant/withdrawals/${id}/export-xlsx/`, setFetchingData, isMounted, axiosPrivate, t, params)()

    const handleExportCsv = (id) => createDowndloadFunction(`/merchant/withdrawals/${id}/export/`, setFetchingData, isMounted, axiosPrivate, t, params)()

    const handleExportPdf = (id) => createDowndloadFunction(`/merchant/withdrawals/${id}/pdf-invoice/`, setFetchingData, isMounted, axiosPrivate, t, params)()

    const retrieveData = useCallback(() => {
        axiosPrivate.get('/merchant/withdrawals/', { params: params })
            .then(({ data: { meta, data } }) => {
                if (isMounted()) {
                    setMeta(meta)
                    setData(data)
                }
            })
            .catch(({ response }) => {
                if (response?.status === 401) {
                    localStorage.removeItem('user')
                    return setAuth({})
                }

                toast.error(t('Something went wrong'))
            })
            .finally(() => setLoading(false))
    }, [params, t, setAuth, axiosPrivate, isMounted])

    useEffect(() => {
        const loadData = () => {
            retrieveData()
        }

        loadData()
    }, [params, retrieveData])

    return (
        <>
            <Card>
                <CardContent>
                    <PageTitle title={t('Settlements')} />

                    <AppFilters Filters={<Filters handleOnSearch={handleOnSearch} />} />

                    <SettlementsTable
                        data={data}
                        pages={meta.total_pages}
                        page={meta.current_page}
                        loading={loading}
                        fetchingData={fetchingData}
                        handleOnPageChange={handleOnPageChange}
                        handleExportXlsx={handleExportXlsx}
                        handleExportCsv={handleExportCsv}
                        handleExportPdf={handleExportPdf}
                    />
                </CardContent>
            </Card>
        </>
    )
}
