import { Stack } from '@mui/material'

export default function Isotipo() {
    return (
        <Stack sx={{ m: 1 }}>
            <img
                src='https://cdn.payretailers.cl/images/payretailers/iso-payretailers-1.png'
                alt="PayRetailers"
                style={{
                    maxWidth: '100px'
                }}
            />
        </Stack>
    )
}
