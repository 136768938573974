import React from 'react';
import { MenuItem, TextField } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

export default function SelectWrapper({
    name,
    options,
    translate = true,
    ...otherProps
}) {
    const { t } = useTranslation()

    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(name);

    const handleChange = event => {
        const { value } = event.target;
        setFieldValue(name, value);
    };

    const configSelect = {
        ...field,
        select: true,
        fullWidth: true,
        variant: 'outlined',
        ...otherProps,
        onChange: handleChange
    };

    if (meta && meta.touched && meta.error) {
        configSelect.error = true;
        configSelect.helperText = meta.error;
    }

    return (
        <TextField {...configSelect}>
            {Object.keys(options).map((item, pos) => (
                <MenuItem key={pos} value={item}>
                    {translate ? t(options[item]) : options[item]}
                </MenuItem>
            ))}
        </TextField>
    )
}
