import { useState, useEffect } from 'react'
import { Card, CardContent } from '@mui/material'
import PageTitle from '../../Misc/PageTitle'
import useIsMounted from '../../../hooks/useIsMounted'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { useTranslation } from 'react-i18next'
import useAuth from '../../../hooks/useAuth'
import { toast } from 'react-toastify'
import AppFilters from '../../AppFilters'
import CashoutsTransactionsTable from './CashoutsTransactionsTable'
import { LoadingButton } from '@mui/lab'
import DescriptionIcon from '@mui/icons-material/Description'
import FiltersCashouts from './FiltersCashouts'
import { axiosCashouts } from '../../../api/index';

export default function CashoutsTransactions() {
  const { t } = useTranslation()

  const { setAuth } = useAuth()

  const [fetchingData, setFetchingData] = useState(false)

  const isMounted = useIsMounted()

  const axiosPrivate = useAxiosPrivate()

  const [loading, setLoading] = useState(true)

  const [params, setParams] = useState({})

  const [meta, setMeta] = useState({
    total_pages: 1,
    current_page: 1
  })

  const [data, setData] = useState([])

  const handleOnSearch = values => {
    setLoading(true)

    values = {
      ...values,
      status: String(values.status).replace(0, ''),
    }

    setParams({
      ...values,
      pg_serviceid: 478227
    })
  }

  const handleOnPageChange = (e, page) => {
    setLoading(true)

    setMeta({ ...meta, current_page: page })
    setParams({ ...params, page: page })
  }

  const handleExport = () => {
    setFetchingData(true)

    axiosCashouts.get('/payouts/export', { params: params })
      .then(({ data, headers }) => {
        const binaryData = []

        binaryData.push(data)

        let anchor = document.createElement('a')

        anchor.href = window.URL.createObjectURL(new Blob(binaryData, { type: "text/csv" }))

        anchor.download = headers.filename

        anchor.click()
      })
      .catch((response) => console.log(response))
      .finally(() => setFetchingData(false))

  }

  useEffect(() => {
    const retrieveData = () => {
      axiosPrivate.get('/merchant/cashouts/', { params: params })
        .then(({ data: { data } }) => {
          if (isMounted()) {
            setData(data)
          }
        })
        .catch(({ response }) => {
          if (response?.status === 401) {
            localStorage.removeItem('user')
            return setAuth({})
          }

          toast.error(t('Something went wrong'))
        })
        .finally(() => setLoading(false))
    }

    retrieveData()
  }, [t, axiosPrivate, isMounted, setAuth, params])

  return (
    <>
      <Card>
        <CardContent>
          <PageTitle title={t('Cashouts')} />

          <div className="tools">
            <LoadingButton
              loading={fetchingData}
              onClick={() => handleExport()}
              loadingPosition="start"
              startIcon={<DescriptionIcon />}
              size="small"
              className="base__btn-action"
            >
              {t('Export')}
            </LoadingButton>
          </div>

          <AppFilters Filters={<FiltersCashouts handleOnSearch={handleOnSearch} />} />

          <CashoutsTransactionsTable
            data={data}
            pages={meta.total_pages}
            page={meta.current_page}
            loading={loading}
            handleOnPageChange={handleOnPageChange}
          />
        </CardContent>
      </Card>
    </>
  )
}
