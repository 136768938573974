const ST_DISABLED = 0
const ST_REQUESTED = 1
const ST_PRODUCTION = 2

const countries = {
    'AR': 'Argentina',
    'BR': 'Brazil',
    'CL': 'Chile',
    'CO': 'Colombia',
    'MX': 'Mexico',
    'PE': 'Peru',
}

const status = {
    0: 'Disabled',
    1: 'Requested',
    2: 'Production',
}

export {
    ST_DISABLED,
    ST_REQUESTED,
    ST_PRODUCTION,
    countries,
    status
}