import { useEffect, useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useTranslation } from 'react-i18next'
import Pagination from '../../Pagination/Pagination'
import TableActions from '../../TableUI/TableActions'
import EditIcon from '@mui/icons-material/Edit'
import { useNavigate } from 'react-router-dom'
import TableBackdrop from '../../TableUI/TableBackdrop'
import VisibilityIcon from '@mui/icons-material/Visibility'
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead'
import CurrencyValue from '../../TableUI/CurrencyValue'
import DatetimeValue from '../../TableUI/DatetimeValue'
import { ST_ACTIVE } from '../../../enums/PaymentLinks'
import { Button, Card, CardActions, CardContent, CardHeader, Grid, Hidden, Stack, Typography } from '@mui/material'
import BackdropWrapper from '../../BackdropWrapper/BackdropWrapper'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import IconButton from '@mui/material/IconButton'
import ShareIcon from '@mui/icons-material/Share'
import { BASE_URL, PAYMENT_WINDOW_URL_QA, PAYMENT_WINDOW_URL } from '../../../api'


export default function PaymentLinksTable({
    data,
    pages = 1,
    page = 1,
    loading = false,
    sending = false,
    handleOnPageChange,
    handleNotification,
    handleOpen,
    handleShareOpen
}) {
    const { t } = useTranslation()

    const navigate = useNavigate()

    const [paymentWindowUrl, setPaymentWindowUrl] = useState()

    const handleNavigate = (view) => {
        navigate(view)
    }

    const handleClick = (token) => {
        window.open(`${paymentWindowUrl}/payment-link/${token}`, "_blank", 'noopener')
    }

    const handleCopyLink = async (token) => {
        if (!token) {
            return false
        }

        const link = `${paymentWindowUrl}/payment-link/${token}`

        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(link);
        } else {
            return document.execCommand('copy', true, link);
        }
    }

    const handleShareLink = (token) => {
        if (!token) {
            return false
        }

        const url = `${paymentWindowUrl}/payment-link/${token}`
        return url
    }

    useEffect(() => {
        setPaymentWindowUrl(
            /gateway/.test(BASE_URL) ? PAYMENT_WINDOW_URL : PAYMENT_WINDOW_URL_QA
        )
    }, [])

    return (
        <>
            <Hidden mdUp={true}>
                <Grid container spacing={.5} justifyContent="center">
                    {
                        data.length && !loading ? data.map(d => (
                            <Grid item xs={12} key={d.id}>
                                <Card>
                                    <CardHeader
                                        title={<Typography variant="h6">{d.title}</Typography>}
                                        subheader={
                                            <CurrencyValue
                                                amount={d.amount}
                                                currency={d.currency}
                                            />
                                        }
                                    />
                                    <CardContent>
                                        <Typography variant="body2" color="text.secondary">
                                            {d.details}
                                        </Typography>

                                        <Stack sx={{
                                            textAlign: 'right',
                                            mt: 2
                                        }}>
                                            <Button
                                                onClick={() => handleCopyLink(d.token)}
                                                endIcon={<ContentCopyIcon />}
                                                variant='outlined'
                                                color='success'
                                                fullWidth
                                            >
                                                {t('Copy Link')}
                                            </Button>
                                        </Stack>
                                    </CardContent>
                                    <CardActions disableSpacing>
                                        <IconButton
                                            aria-label="preview"
                                            onClick={() => handleClick(d.token)}
                                        >
                                            <VisibilityIcon />
                                        </IconButton>
                                        <IconButton
                                            aria-label="edit"
                                            onClick={() => handleNavigate(`edit/${d.id}`)}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton
                                            aria-label="send email"
                                            onClick={() => handleOpen(d.id)}
                                        >
                                            <MarkEmailReadIcon />
                                        </IconButton>
                                        <IconButton
                                            aria-label={"Compartir"}
                                            onClick={() => {handleShareOpen(handleShareLink(d.token))}}
                                        >
                                            <ShareIcon/>
                                        </IconButton>
                                    </CardActions>
                                </Card>
                            </Grid>
                        )) : (loading ? <BackdropWrapper open={loading} /> : t('No records found'))
                    }
                </Grid>
            </Hidden>

            <Hidden mdDown={true}>
                <Paper sx={{
                    width: {
                        xs: 'calc(100vw - 80px)',
                        sm: 'calc(100vw - 330px)'
                    }, overflow: 'hidden'
                }}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('Title')}</TableCell>
                                    <TableCell align="right">{t('Amount')}</TableCell>
                                    <TableCell align="center">{t('Currency')}</TableCell>
                                    <TableCell align="center">{t('Creation date')}</TableCell>
                                    <TableCell align="right">&nbsp;</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.length && !loading ? data.map((row) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell>{row.title}</TableCell>
                                        <TableCell align="right">
                                            <CurrencyValue
                                                amount={row.amount}
                                                currency={row.currency}
                                            />
                                        </TableCell>
                                        <TableCell align="center">{row.currency}</TableCell>
                                        <TableCell align="center">
                                            <DatetimeValue date={row.created_at} />
                                        </TableCell>
                                        <TableCell align="right">
                                            <Stack direction="row" justifyContent="flex-end">
                                                <Button
                                                    onClick={() => handleCopyLink(row.token)}
                                                    startIcon={<ContentCopyIcon />}>
                                                    {t('Copy Link')}
                                                </Button>
                                                <TableActions actions={[
                                                    {
                                                        id: `preview-${row.id}`,
                                                        handleClick: () => { handleClick(row.token) },
                                                        icon: <VisibilityIcon />,
                                                        title: t('Preview')
                                                    },
                                                    {
                                                        id: `edit-${row.id}`,
                                                        handleClick: () => { handleNavigate(`edit/${row.id}`) },
                                                        icon: <EditIcon />,
                                                        title: t('Edit')
                                                    },
                                                    {
                                                        id: `email-${row.id}`,
                                                        handleClick: () => handleOpen(row.id),
                                                        icon: <MarkEmailReadIcon />,
                                                        title: t('Send'),
                                                        disabled: row.status !== ST_ACTIVE
                                                    },
                                                    {
                                                        id: `copy-${row.id}`,
                                                        handleClick: () => handleCopyLink(row.token),
                                                        icon: <ContentCopyIcon />,
                                                        title: t('Copy Link'),
                                                        disabled: row.status !== ST_ACTIVE
                                                    },
                                                    {
                                                        id: `share-${row.id}`,
                                                        handleClick: () => {handleShareOpen(handleShareLink(row.token))},
                                                        icon: <ShareIcon />,
                                                        title: t('Compartir'),
                                                        disabled: row.status !== ST_ACTIVE
                                                    }
                                                ]} />
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                )) : <TableRow>
                                    {
                                        loading ? <TableBackdrop open={loading} /> : <TableCell sx={{ textAlign: 'center' }} colSpan={100}>{t('No records found')}</TableCell>
                                    }
                                </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Hidden>

            <Pagination
                handleOnPageChange={handleOnPageChange}
                page={page}
                pages={pages}
            />
        </>
    )
}
